enum FeeMandatoryType {
    None,
    ElectronicInvoice,
    DirectDebit,
    Both,
}

const feeMandatoryTypeMap: { [key in FeeMandatoryType]: string } = {
    [FeeMandatoryType.None]: "Ingen",
    [FeeMandatoryType.ElectronicInvoice]: "E-Faktura",
    [FeeMandatoryType.DirectDebit]: "Avtalegiro",
    [FeeMandatoryType.Both]: "Både E-Faktura og Avtalegiro",
};

const feeMandatoryTypeFlippedMap: { [key: string]: FeeMandatoryType } =
    Object.entries(feeMandatoryTypeMap).reduce(
        (prev, curr) => ({
            ...prev,
            [curr[1]]: Number(curr[0]),
        }),
        {},
    );

const DbFeeMandatoryTypeMap: {
    [key in FeeMandatoryType]:
        | "none"
        | "electronicInvoice"
        | "directDebit"
        | "both";
} = {
    [FeeMandatoryType.None]: "none",
    [FeeMandatoryType.ElectronicInvoice]: "electronicInvoice",
    [FeeMandatoryType.DirectDebit]: "directDebit",
    [FeeMandatoryType.Both]: "both",
};

const DbFeeMandatoryTypeFlippedMap: { [key: string]: FeeMandatoryType } =
    Object.entries(DbFeeMandatoryTypeMap).reduce(
        (prev, curr) => ({
            ...prev,
            [curr[1]]: Number(curr[0]),
        }),
        {},
    );

type FeeMandatoryTypeValues =
    (typeof feeMandatoryTypeMap)[keyof typeof feeMandatoryTypeMap];

type DBFeeMandatoryTypeValues =
    (typeof feeMandatoryTypeMap)[keyof typeof feeMandatoryTypeMap];

const DbtoEnumValue = (
    value: DBFeeMandatoryTypeValues | null,
): FeeMandatoryType | null =>
    value !== null ? DbFeeMandatoryTypeFlippedMap[value] : null;

const DbtoHumanValue = (
    value: FeeMandatoryType | null,
): DBFeeMandatoryTypeValues | null =>
    value !== null ? DbFeeMandatoryTypeMap[value] : null;

const toEnumValue = (value: string): FeeMandatoryType =>
    feeMandatoryTypeFlippedMap[value];

const toHumanValue = (value: FeeMandatoryType): string =>
    feeMandatoryTypeMap[value];

export default FeeMandatoryType;

export {
    toEnumValue,
    toHumanValue,
    DbtoHumanValue,
    DbtoEnumValue,
    type FeeMandatoryTypeValues,
    type DBFeeMandatoryTypeValues,
};
