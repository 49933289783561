import { CSSProperties } from "react";
import classNames from "classnames";

const FieldError = ({
    error,
    className,
    style,
}: {
    error: string | undefined;
    className?: string;
    style?: CSSProperties;
}) => {
    if (error === undefined) {
        return null;
    }

    return (
        <span className={classNames("block is-invalid mt-2", className)} style={style}>
            {error}
        </span>
    );
};

export default FieldError;
