enum FeeType {
    Before,
    After,
    Period,
}

const feeTypeMap: { [key in FeeType]: string } = {
    [FeeType.After]: "På etterskudd (anbefalt)",
    [FeeType.Before]: "På forskudd",
    [FeeType.Period]: "I perioden",
};

type FeeTypeValues = (typeof feeTypeMap)[keyof typeof feeTypeMap];

const toHumanValue = (value: FeeType): string => feeTypeMap[value];

export default FeeType;
export { toHumanValue, type FeeTypeValues };

const DbfeeTypeMap: { [key in FeeType]: "before" | "after" | "period" } = {
    [FeeType.Before]: "before",
    [FeeType.After]: "after",
    [FeeType.Period]: "period",
};

const DbfeeTypeFlippedMap: { [key: string]: FeeType } = Object.entries(
    DbfeeTypeMap,
).reduce(
    (prev, curr) => ({
        ...prev,
        [curr[1]]: Number(curr[0]),
    }),
    {},
);

type DbFeeTypeValues = (typeof DbfeeTypeMap)[keyof typeof DbfeeTypeMap];

const DbtoHumanValue = (value: FeeType | null): DbFeeTypeValues | null =>
    value !== null ? DbfeeTypeMap[value] : null;

const DbtoEnumValue = (value: DbFeeTypeValues | null): FeeType | null =>
    value !== null ? DbfeeTypeFlippedMap[value] : null;

export { type DbFeeTypeValues, DbtoEnumValue, DbtoHumanValue };
