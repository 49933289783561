import { stringSuffix } from "./string";
const format = (
    n: number | null | undefined,
    maximumFractionDigits: number = 0,
): string => {
    if (!numExists(n)) {
        return "NaN";
    }

    return new Intl.NumberFormat("nb-NO", {
        maximumFractionDigits,
    }).format(n || 0);
};

const valNum = (num: unknown): string | null => {
    if (num === "" || (num !== "-" && isNaN(Number(num)))) {
        return null;
    }

    return String(num);
};

/**
 * checks if num exists
 * @param num
 * @returns 1 = true, 0 = true, null = false, undeined = false, NaN = true
 */
const numExists = (num: number | string | null | undefined) =>
    num !== null && num !== undefined;

/**
 *
 * @param n
 * @param formaterOptions any options for formaters
 * @param customFormater use a diferent formater than format()
 * @description check if n is NaN, if it ain't return format(n), else return "Ikke oppgitt"
 * @returns {string}
 */
const handleNaN = (
    n: number | undefined | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formaterOptions: any[] = [],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customFormater?: (n: number, ...rest: any) => string,
): string => {
    if (numExists(n) && !isNaN(n || 0)) {
        if (customFormater !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return customFormater(n || 0, ...formaterOptions);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return format(n, ...formaterOptions);
    }
    return "Ikke oppgitt";
};

const formatString = (val: number | string | null | undefined) => {
    if (val === null) {
        return null;
    }

    if (val === undefined) {
        return undefined;
    }

    return Number(String(val).replace(",", "."));
};

/**
 *  shortend function for stringSufix() and handleNaN()
 * @param n
 * @param sufix
 * @param formatOptions
 * @param customFormater
 * @returns
 */
const suffixAndNaN = (
    n: number | string | null | undefined,
    sufix?: string,
    formatOptions?: unknown[],
    customFormater?: (n: number, ...rest: unknown[]) => string,
): string =>
    stringSuffix(
        handleNaN(Number(n), formatOptions, customFormater),
        sufix || "",
    );

export { format, valNum, handleNaN, numExists, formatString, suffixAndNaN };
