import React from "react";
import Select, { SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";

import "./SearchSelect.scss";

export type SearchSelectOption = {
    value: null | string | number;
    label: string;
};

export type GroupedSearchSelectOption = {
    label?: string;
    options: SearchSelectOption[];
};

const SearchSelectGroupLabel = (data: GroupedSearchSelectOption) => (
    <div className="flex content-center gap-2">
        <span className="flex-grow">{data.label}</span>
        <span className="flex-basis-0 pill pill-admin">
            {data.options.length}
        </span>
    </div>
);

const SearchSelect: React.FC<{
    options: (SearchSelectOption | GroupedSearchSelectOption)[];
    value: SearchSelectOption | undefined;
    onChange: (val: SingleValue<SearchSelectOption>) => void;
    placeholder?: string;
    isSearchable?: boolean;
    onCreateOption?: (value: string) => void;
}> = ({
    options,
    value,
    onChange,
    placeholder = "Velg...",
    onCreateOption = undefined,
    isSearchable = true,
}) => {
    let Component: CreatableSelect | Select = Select;

    if (onCreateOption !== undefined) {
        Component = CreatableSelect;
    }
    return (
        <Component<SearchSelectOption, false, GroupedSearchSelectOption>
            options={options}
            value={value}
            onChange={onChange}
            isSearchable={isSearchable}
            formatGroupLabel={SearchSelectGroupLabel}
            placeholder={placeholder}
            styles={{
                indicatorsContainer: (base) => ({
                    ...base,
                    display: "none",
                }),
                valueContainer: (base) => ({
                    ...base,
                    padding: "10px 12px",
                    borderColor: "var(--color-border-primary-emphasis)"
                }),
                singleValue: (base) => ({
                    ...base,
                    marginLeft: undefined,
                    marginRight: undefined,
                }),
                input: (base) => ({
                    ...base,
                    margin: undefined,
                    paddingTop: undefined,
                    paddingBottom: undefined,
                }),
                group: (base) => ({
                    ...base,
                    margin: undefined,
                    paddingTop: "var(--spacing-2)",
                    paddingBottom: undefined,
                }),
            }}
            isClearable
            noOptionsMessage={() => "Ingen resultater ble funnet"}
            onCreateOption={onCreateOption}
            formatCreateLabel={(value: string) => `Opprett "${value}"`}
        />
    );
};

//

export default SearchSelect;
