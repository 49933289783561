enum TOSAlertChannel {
    Email,
    Sms,
    PostalLetter,
    Website,
    MyPage,
    Phone,
}

const tosAlertChannelMap: { [key in TOSAlertChannel]: string } = {
    [TOSAlertChannel.Email]: "E-post",
    [TOSAlertChannel.Sms]: "SMS",
    [TOSAlertChannel.PostalLetter]: "Brev",
    [TOSAlertChannel.Website]: "Egen nettside",
    [TOSAlertChannel.MyPage]: "Min side",
    [TOSAlertChannel.Phone]: "Telefon",
};

type TOSAlertChannelValues =
    (typeof tosAlertChannelMap)[keyof typeof tosAlertChannelMap];

const validOptions: TOSAlertChannel[] = [
    TOSAlertChannel.Email,
    TOSAlertChannel.Sms,
    TOSAlertChannel.PostalLetter,
    TOSAlertChannel.Phone,
];

const isValidOption = (tosAlertChannel: TOSAlertChannel) =>
    validOptions.includes(tosAlertChannel);

const toHumanValue = (value: TOSAlertChannel): string =>
    tosAlertChannelMap[value] || "Ikke oppgitt";

const DbTOSAlertChannelMap: {
    [key in TOSAlertChannel]:
        | "email"
        | "sms"
        | "postal letter"
        | "website"
        | "login website"
        | "phone";
} = {
    [TOSAlertChannel.Email]: "email",
    [TOSAlertChannel.Sms]: "sms",
    [TOSAlertChannel.PostalLetter]: "postal letter",
    [TOSAlertChannel.Website]: "website",
    [TOSAlertChannel.MyPage]: "login website",
    [TOSAlertChannel.Phone]: "phone",
};

type DbTOSAlertChannelValues =
    (typeof DbTOSAlertChannelMap)[keyof typeof DbTOSAlertChannelMap];

const DbtosAlertChannelFlippedMap: { [key: string]: TOSAlertChannel } =
    Object.entries(DbTOSAlertChannelMap).reduce(
        (prev, curr) => ({
            ...prev,
            [curr[1]]: Number(curr[0]),
        }),
        {},
    );

const dbToEnumValue = (
    value: DbTOSAlertChannelValues | null,
): TOSAlertChannel | null =>
    value !== null ? DbtosAlertChannelFlippedMap[value] : null;

const dbToHumanValue = (
    value: TOSAlertChannel | null,
): DbTOSAlertChannelValues | null =>
    value !== null ? DbTOSAlertChannelMap[value] : null;

export {
    type DbTOSAlertChannelValues,
    dbToEnumValue,
    dbToHumanValue,
    DbTOSAlertChannelMap,
    toHumanValue,
    validOptions,
    isValidOption,
    type TOSAlertChannelValues,
};

export default TOSAlertChannel;
