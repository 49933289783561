import axios from "../util/axios";
import { ProductVersion } from "../types";
import { useMutation } from "react-query";
import { versionToDbHuman } from "../util/productVersion";

export default function useUpdateProduct(
    version: ProductVersion,
    options?: { id?: number; organizationId?: number },
) {
    const dbVersion = versionToDbHuman(version);

    const { id, organizationId } = options || {};

    if (id) {
        const updateProducts = async () => {
            return await axios.put(`products/${id}`, {
                ...dbVersion,
                organizationId,
            });
        };

        return useMutation<{
            data: {
                success: boolean;
                draft: boolean;
                message: string;
                productId: number;
                productVersionId: number;
            };
        }>({
            mutationKey: ["products", id],
            mutationFn: updateProducts,
        });
    } else {
        const createProduct = async () => {
            return await axios.post(`products`, dbVersion);
        };

        return useMutation<{
            data: {
                success: boolean;
                draft: boolean;
                message: string;
                productId: number;
                productVersionId: number;
            };
        }>({
            mutationKey: ["products", id],
            mutationFn: createProduct,
        });
    }
}
