import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { isFuture } from "date-fns";
import axios from "../util/axios";
import { AuthProfile } from "../types";
import { useStore } from ".";

export default function useAuthProfiles(slug: string | null = null) {
    const { authToken } = useStore();
    const retrieveAuthProfiles = async (
        slug: string | null,
    ): Promise<AuthProfile[]> => {
        const response: AxiosResponse<AuthProfile[]> = await axios.get(
            `auth_profiles${slug ? `/slug/${slug}` : ""}`,
        );

        return response.data;
    };

    const createAuthProfile = async (body: {
        organizationId: number | null;
        name: string;
        email: string;
    }) => {
        const response: AxiosResponse<AuthProfile> = await axios.post(
            "auth_profiles",
            body,
        );

        return response.data;
    };

    const updateAuthProfile = async (
        authProfileId: number,
        data: { name: string; email: string },
    ) => {
        const response: AxiosResponse<{ success: boolean }> = await axios.put(
            `auth_profiles/${authProfileId}`,
            data,
        );

        return response.data;
    };

    const deleteAuthProfile = async (authProfileId: number) => {
        const response: AxiosResponse<{ success: boolean }> =
            await axios.delete(`auth_profiles/${authProfileId}`);

        return response.data;
    };

    const regenerateSecret = async (authProfileId: number) => {
        const response: AxiosResponse<AuthProfile> = await axios.put(
            `auth_profiles/secret/${authProfileId}`,
        );

        return response.data;
    };

    return {
        ...useQuery(
            ["auth_profiles", slug],
            async () => await retrieveAuthProfiles(slug),
            {
                retry: 3,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                refetchOnReconnect: false,
                enabled:
                    authToken !== null &&
                    isFuture(new Date(authToken.expiresAt)),
            },
        ),
        createAuthProfile,
        updateAuthProfile,
        deleteAuthProfile,
        regenerateSecret,
    };
}
