enum ProductType {
    Spot,
    HourlySpot,
    Fixed,
    Variable,
    Purchase,
    Plus,
    Other,
}

const productTypeMap: { [key in ProductType]: string } = {
    [ProductType.Spot]: "Spotpris",
    [ProductType.HourlySpot]: "Timespot",
    [ProductType.Fixed]: "Fastpris",
    [ProductType.Variable]: "Variabelpris",
    [ProductType.Purchase]: "Innkjøpspris",
    [ProductType.Plus]: "Plussavtale",
    [ProductType.Other]: "Annen prismodell",
};

const productTypeFlippedMap: { [key: string]: ProductType } = Object.entries(
    productTypeMap,
).reduce(
    (prev, curr) => ({
        ...prev,
        [curr[1]]: Number(curr[0]),
    }),
    {},
);

const predictableOptions: ProductType[] = [
    ProductType.Fixed,
    ProductType.Variable,
];

const comparableOptions: ProductType[] = [
    ProductType.Spot,
    ProductType.HourlySpot,
    ProductType.Fixed,
    ProductType.Variable,
    ProductType.Purchase,
    ProductType.Plus,
];

const isPredictableOption = (productType: ProductType): boolean =>
    predictableOptions.includes(productType);

const isComparableOption = (productType: ProductType): boolean =>
    comparableOptions.includes(productType);

const toEnumValue = (value: string): ProductType =>
    productTypeFlippedMap[value];

const toHumanValue = (value: ProductType | null): string | null =>
    value !== null ? productTypeMap[value] : null;

const DbproductTypeMap: {
    [key in ProductType]:
        | "spot"
        | "hourly_spot"
        | "fixed"
        | "variable"
        | "purchase"
        | "plus"
        | "other";
} = {
    [ProductType.Spot]: "spot",
    [ProductType.HourlySpot]: "hourly_spot",
    [ProductType.Fixed]: "fixed",
    [ProductType.Variable]: "variable",
    [ProductType.Purchase]: "purchase",
    [ProductType.Plus]: "plus",
    [ProductType.Other]: "other",
};

type DbProductTypeValues =
    (typeof DbproductTypeMap)[keyof typeof DbproductTypeMap];

const DbProductTypeFlippedMap: { [key: string]: ProductType } = Object.entries(
    DbproductTypeMap,
).reduce(
    (prev, curr) => ({
        ...prev,
        [curr[1]]: Number(curr[0]),
    }),
    {},
);

const dbToEnumValue = (
    value: DbProductTypeValues | null,
): ProductType | null =>
    value !== null ? DbProductTypeFlippedMap[value] : null;

const dbToHumanValue = (
    value: ProductType | null,
): DbProductTypeValues | null =>
    value !== null ? DbproductTypeMap[value] : null;

export {
    type DbProductTypeValues,
    dbToEnumValue,
    dbToHumanValue,
    DbproductTypeMap,
    toEnumValue,
    toHumanValue,
    productTypeMap,
    comparableOptions,
    isComparableOption,
    predictableOptions,
    isPredictableOption,
};
export default ProductType;
