import { create } from "zustand";
import { addMilliseconds } from "date-fns";
import type { User } from "../types";
import { ReactNode } from "react";

export type ExternalAuthToken = {
    accessToken: string;
    refreshToken: string;
    tokenType: "Bearer";
    expiresIn: number;
    refreshTokenExpiresIn: number;
};

export type AuthToken = Omit<
    ExternalAuthToken,
    "expiresIn" | "refreshTokenExpiresIn"
> & {
    // TODO: We shouldn't store email in local storage, but due to how auth implementation is implemented this is necessary for now... should look into changing the refresh token endpoint to make use of the access token as identifier
    email: string;
    expiresAt: Date;
    refreshExpiresAt: Date;
};

type State = {
    authToken: AuthToken | null;
    setAuthToken: (email: string, authToken: ExternalAuthToken | null) => void;
    user: User | null;
    setUser: (user: User | null) => void;
    otpEmail: string | null;
    setOtpEmail: (email: string | null) => void;
    currentModal: ReactNode;
    setCurrentModal: (currentModal: ReactNode) => void;
    modalEnabled: boolean;
    setModalEnabled: (modalEnabled: boolean) => void;
    menuExpanded: boolean;
    setMenuExpanded: (menuExpanded: boolean) => void;
    menuAnimating: boolean;
    setMenuAnimating: () => void;
};

const useStore = create<State>((set) => ({
    authToken: JSON.parse(
        localStorage.getItem("accessToken") || "null",
    ) as AuthToken | null,
    setAuthToken: (email: string, authToken: ExternalAuthToken | null) => {
        let parsedAccessToken: AuthToken | null;
        if (authToken === null) {
            parsedAccessToken = null;
            localStorage.removeItem("accessToken");
        } else {
            parsedAccessToken = {
                email,
                accessToken: authToken.accessToken,
                refreshToken: authToken.refreshToken,
                tokenType: authToken.tokenType,
                expiresAt: addMilliseconds(new Date(), authToken.expiresIn),
                refreshExpiresAt: addMilliseconds(
                    new Date(),
                    authToken.refreshTokenExpiresIn,
                ),
            };

            localStorage.setItem(
                "accessToken",
                JSON.stringify(parsedAccessToken),
            );
        }

        return set((state) => ({
            ...state,
            authToken: parsedAccessToken,
        }));
    },
    otpEmail: sessionStorage.getItem("otpEmail") || null,
    setOtpEmail: (email: string | null) => {
        if (email === null) {
            sessionStorage.removeItem("otpEmail");
        } else {
            sessionStorage.setItem("otpEmail", email);
        }

        return set((state) => ({
            ...state,
            otpEmail: email,
        }));
    },
    user: null,
    setUser: (user: User | null) => set((state) => ({ ...state, user })),
    currentModal: null,
    setCurrentModal: (currentModal: ReactNode) => set({ currentModal }),
    modalEnabled: false,
    setModalEnabled: (modalEnabled: boolean) => set({ modalEnabled }),
    menuExpanded: false,
    setMenuExpanded: (menuExpanded: boolean) => set({ menuExpanded }),
    menuAnimating: false,
    setMenuAnimating: () => {
        set({ menuAnimating: true });
        setTimeout(
            () => set({ menuAnimating: false, menuExpanded: false }),
            400,
        );
    },
}));

export default useStore;
