import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { isFuture } from "date-fns";
import axios from "../util/axios";
import type { UserRole } from "../types";
import { useStore } from ".";

export default function useRoles() {
    const { authToken } = useStore();
    const retrieveRoles = async (): Promise<UserRole[]> => {
        const response: AxiosResponse<UserRole[]> = await axios.get("roles");

        return response.data;
    };

    return useQuery("roles", async () => await retrieveRoles(), {
        retry: 3,
        enabled: authToken !== null && isFuture(new Date(authToken.expiresAt)),
    });
}
