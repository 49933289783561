import React, { ReactNode } from "react";
import classNames from "classnames";
import "./ConfirmationModal.scss";

const ConfirmationModal: React.FC<{
    label: ReactNode;
    confirmBtnType?: "primary" | "danger";
    cancelBtnType?: "secondary";
    isOpen: boolean;
    labelConfirm?: string;
    labelCancel?: string;
    onClose: () => void;
    onConfirm: () => void;
}> = ({
    isOpen,
    label,
    labelConfirm = "Bekreft",
    labelCancel = "Avbryt",
    confirmBtnType = "primary",
    cancelBtnType = "secondary",
    onClose,
    onConfirm,
}) => {
    return (
        <>
            <div
                className={classNames("confirmation-modal", {
                    block: isOpen,
                    hidden: !isOpen,
                })}
            >
                {label}
                <div className="button-container">
                    <button
                        className={classNames("modal-btn mr-4", {
                            "bg-primary": confirmBtnType === "primary",
                            "bg-danger": confirmBtnType === "danger",
                        })}
                        onClick={onConfirm}
                    >
                        {labelConfirm}
                    </button>
                    <button
                        className={classNames("modal-btn mr-4", {
                            "bg-secondary": cancelBtnType === "secondary",
                        })}
                        onClick={onClose}
                    >
                        {labelCancel}
                    </button>
                </div>
            </div>
        </>
    );
};

export default ConfirmationModal;
