import { useNavigate, To } from "react-router-dom";
import { Button } from "frno-react";

const BackButton = ({
    label = "Tilbake",
    type = "featured",
    icon = "fa-chevron-left",
    onClick = undefined,
    to = -1,
}: {
    label?: string;
    icon?: string;
    type?: "featured" | "secondary" | "default" | "primary" | "chip";
    to?: number | string;
    onClick?: () => void;
}) => {
    const navigate = useNavigate();

    return (
        <Button
            className="btn-admin btn-sm"
            type={type}
            onClick={onClick !== undefined ? onClick : () => navigate(to as To)}
        >
            {icon && <i className={`fa-regular ${icon}`} />}
            {label}
        </Button>
    );
};

export default BackButton;
