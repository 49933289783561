import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { isFuture } from "date-fns";
import axios from "../util/axios";
import type { Organization, Product } from "../types";
import { DbVersionToEnumValue } from "../util/productVersion";
import { DbHumanProductVersion } from "../types/productVersion";
import { type DbProductTypeValues } from "../enum/productType";
import { type DbPublishingStatusValues } from "../enum/publishingStatus";
import { useStore } from ".";

interface DbProduct {
    id: number;
    name: string;
    type: DbProductTypeValues;
    status: DbPublishingStatusValues;
    organization: Organization;
    versions: DbHumanProductVersion[];
    createdAt: Date | null;
    updatedAt: Date | null;
    activatedAt: Date | null;
    deletedAt: Date | null;
}

export default function useProduct(id: number) {
    const { authToken } = useStore();
    const retrieveProduct = async (id: number): Promise<Product> => {
        const response: AxiosResponse<DbProduct> = await axios.get(
            `products/${id}`,
        );

        const resp: AxiosResponse<Product> = {
            ...response,
            data: {
                ...response.data,
                versions: response.data.versions.map((version) =>
                    DbVersionToEnumValue(version),
                ),
            },
        };

        return resp.data;
    };

    return useQuery(["product", id], async () => await retrieveProduct(id), {
        retry: 3,
        enabled: authToken !== null && isFuture(new Date(authToken.expiresAt)),
    });
}
