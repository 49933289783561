import { FocusEvent, useRef } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { Link } from "react-router-dom";
import {
    useStore,
    useLegalEntities,
    usePendingProducts,
    useUser,
} from "../../hooks";
import { isAdmin, isGuest } from "../../util/user";
import "./index.scss";
const Index = () => {
    const {
        authToken,
        menuAnimating,
        menuExpanded,
        setMenuAnimating,
        setMenuExpanded,
    } = useStore();
    const { data: pendingProducts, isLoading: isLoadingPendingProducts } =
        usePendingProducts();
    const { data: legalEntities, isLoading: isLoadingLegalEntities } =
        useLegalEntities();
    const { data: user } = useUser();
    const location = useLocation();
    const sidebarRef = useRef<HTMLDivElement>({} as HTMLDivElement);

    const handleMenuExpansion = (value: boolean) => {
        if (value) {
            setMenuExpanded(value);
        } else {
            setMenuAnimating();
        }
    };

    const handleBlurOfSidebar = (e: FocusEvent<HTMLDivElement>) => {
        if (menuExpanded && !sidebarRef.current?.contains(e.relatedTarget)) {
            handleMenuExpansion(false);
        }
    };

    if (isGuest(user) || authToken === null) {
        return null;
    }

    return (
        <aside
            className={classNames("admin-sidebar", {
                "is-expanded": menuExpanded,
                "animate-reverse": menuAnimating,
            })}
            ref={sidebarRef}
            onBlur={handleBlurOfSidebar}
        >
            <nav>
                <ul>
                    {isAdmin(user) && (
                        <li
                            className={classNames({
                                active: location.pathname === "/",
                            })}
                        >
                            <Link
                                to="/"
                                onClick={() => handleMenuExpansion(false)}
                            >
                                <i className="fa-regular fa-house" />
                                {isAdmin(user) &&
                                    !isLoadingPendingProducts &&
                                    pendingProducts &&
                                    pendingProducts.count > 0 && (
                                        <span className="notification">
                                            <span className="notification__text">
                                                {pendingProducts.count}
                                            </span>
                                        </span>
                                    )}
                                <span>Dashboard</span>
                            </Link>
                        </li>
                    )}

                    {isAdmin(user) ||
                    (user?.organizations && user?.organizations.length > 1) ? (
                        <li
                            className={classNames({
                                active:
                                    location.pathname.startsWith(
                                        "/leverandorer",
                                    ) &&
                                    !location.pathname.startsWith(
                                        "/leverandorer/uregistrerte",
                                    ),
                            })}
                        >
                            <Link
                                to="/leverandorer"
                                onClick={() => handleMenuExpansion(false)}
                            >
                                <i className="fa-regular fa-cube" />
                                <span>Leverandører</span>
                            </Link>
                        </li>
                    ) : (
                        <li
                            className={classNames({
                                active:
                                    location.pathname.startsWith(
                                        "/leverandorer",
                                    ) &&
                                    !location.pathname.startsWith(
                                        "/leverandorer/uregistrerte",
                                    ),
                            })}
                        >
                            <Link
                                to={`/leverandorer/${
                                    (user?.organizations?.length || 0) > 1
                                        ? ""
                                        : user?.organizations?.length
                                        ? user?.organizations[0].slug
                                        : ""
                                }`}
                            >
                                <i className="fa-regular fa-cube" />
                                <span>
                                    {(user?.organizations?.length || 0) > 1
                                        ? "Leverandører"
                                        : "Leverandør"}
                                </span>
                            </Link>
                        </li>
                    )}
                    {isAdmin(user) && (
                        <>
                            <li
                                className={classNames({
                                    active: location.pathname.startsWith(
                                        "/leverandorer/uregistrerte",
                                    ),
                                })}
                            >
                                <Link
                                    to="/leverandorer/uregistrerte"
                                    onClick={() => handleMenuExpansion(false)}
                                >
                                    <i className="fa-light fa-cubes-stacked" />
                                    <span>
                                        Uregistrerte leverandører{" "}
                                        <span className="fs-small">
                                            {!isLoadingLegalEntities &&
                                                `(${legalEntities?.length})`}
                                        </span>
                                    </span>
                                </Link>
                            </li>
                            <li
                                className={classNames({
                                    active:
                                        location.pathname.startsWith(
                                            "/produkter",
                                        ) &&
                                        !location.pathname.startsWith(
                                            "/produkter/endringslogg",
                                        ),
                                })}
                            >
                                <Link
                                    to="/produkter"
                                    onClick={() => handleMenuExpansion(false)}
                                >
                                    <i className="fa-regular fa-bolt" />
                                    <span>Strømavtaler</span>
                                </Link>
                            </li>
                            <li
                                className={classNames({
                                    active: location.pathname.startsWith(
                                        "/produkter/endringslogg",
                                    ),
                                })}
                            >
                                <Link
                                    to="/produkter/endringslogg"
                                    onClick={() => handleMenuExpansion(false)}
                                >
                                    <i className="fa-regular fa-code-branch" />
                                    <span>Endringslogg</span>
                                </Link>
                            </li>

                            <li
                                className={classNames({
                                    active:
                                        location.pathname.startsWith(
                                            "/brukere",
                                        ) &&
                                        !location.pathname.startsWith(
                                            "/brukere/eksterne-konsumenter",
                                        ),
                                })}
                            >
                                <Link
                                    to="/brukere"
                                    onClick={() => handleMenuExpansion(false)}
                                >
                                    <i className="fa-regular fa-users" />
                                    <span>Brukere</span>
                                </Link>
                            </li>
                            <li
                                className={classNames({
                                    active: location.pathname.startsWith(
                                        "/brukere/eksterne-konsumenter",
                                    ),
                                })}
                            >
                                <Link
                                    to="/brukere/eksterne-konsumenter"
                                    onClick={() => handleMenuExpansion(false)}
                                >
                                    <i className="fa-light fa-circle-nodes" />
                                    <span>Eksterne konsumenter</span>
                                </Link>
                            </li>
                            <li
                                className={classNames({
                                    active: location.pathname.startsWith(
                                        "/medlemskap",
                                    ),
                                })}
                            >
                                <Link
                                    to="/medlemskap"
                                    onClick={() => handleMenuExpansion(false)}
                                >
                                    <i className="fa-regular fa-image-user" />
                                    <span>Medlemskap</span>
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
                <hr className="my-5 border-primary" />
                <ul>
                    <li
                        className={classNames({
                            active: location.pathname.startsWith("/rutiner"),
                        })}
                    >
                        <Link
                            to="/rutiner"
                            onClick={() => handleMenuExpansion(false)}
                        >
                            <i className="fa-regular fa-file-lines" />
                            <span>Rutiner</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </aside>
    );
};

export default Index;
