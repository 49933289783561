enum PriceType {
    Kilowatt,
    Spot,
    Arrears,
    OnRequest,
}

const priceTypeMap: { [key in PriceType]: string } = {
    [PriceType.Kilowatt]: "Fast kWt-pris",
    [PriceType.Spot]: "Spotbasert (påslag per kWt + spotpris fra Nordpool)",
    [PriceType.Arrears]: "Etterskuddvis (pris settes etter perioden)",
    [PriceType.OnRequest]: "Fastpris (pris oppgis på forespørsel)",
};

const priceTypePlusMap: { [key in PriceType]?: string } = {
    [PriceType.Kilowatt]: "Fast kWt-pris",
    [PriceType.Spot]:
        "Spotbasert (Forbruker får NordPool spotpris + et påslag/avslag per kWt for strømmen de selger)",
};

type PriceTypeValues = (typeof priceTypeMap)[keyof typeof priceTypeMap];
type PriceTypePlusValues =
    (typeof priceTypePlusMap)[keyof typeof priceTypePlusMap];

const priceTypeFlippedMap: { [key: string]: PriceType } = Object.entries(
    priceTypeMap,
).reduce(
    (prev, curr) => ({
        ...prev,
        [curr[1]]: Number(curr[0]),
    }),
    {},
);

const toEnumValue = (value: string): PriceType => priceTypeFlippedMap[value];

const toHumanValue = (value: PriceType, plus: boolean = false): string =>
    plus ? (priceTypePlusMap[value] as string) : priceTypeMap[value];

const DbpriceTypeMap: {
    [key in PriceType]: "kilowatt" | "spot" | "arrears" | "request";
} = {
    [PriceType.Kilowatt]: "kilowatt",
    [PriceType.Spot]: "spot",
    [PriceType.Arrears]: "arrears",
    [PriceType.OnRequest]: "request",
};

type DbPriceTypeValues = (typeof DbpriceTypeMap)[keyof typeof DbpriceTypeMap];

const DbpriceTypeFlippedMap: { [key: string]: PriceType } = Object.entries(
    DbpriceTypeMap,
).reduce(
    (prev, curr) => ({
        ...prev,
        [curr[1]]: Number(curr[0]),
    }),
    {},
);

const dbToEnumValue = (value: DbPriceTypeValues | null): PriceType | null =>
    value !== null ? DbpriceTypeFlippedMap[value] : null;

const dbToHumanValue = (value: PriceType | null): DbPriceTypeValues | null =>
    value !== null ? DbpriceTypeMap[value] : null;

export {
    toEnumValue,
    toHumanValue,
    type PriceTypeValues,
    type PriceTypePlusValues,
    type DbPriceTypeValues,
    DbpriceTypeFlippedMap,
    DbpriceTypeMap,
    dbToEnumValue,
    dbToHumanValue,
};
export default PriceType;
