import { useQuery } from "react-query";
import axios from "../util/axios";
import { AxiosResponse } from "axios";
import type { Area } from "../types";

export default function useAreas() {
    const retrieveAreas = async (): Promise<Area[]> => {
        const response: AxiosResponse<Area[]> = await axios.get("areas");

        return response.data;
    };

    return useQuery({
        queryFn: retrieveAreas,
        queryKey: ["areas"],
        retry: 3,
    });
}
