import { Link } from "react-router-dom";
import { format, isValid } from "date-fns";
import TOSAlertChannel, {
    toHumanValue as tosAlertChannelToHumanValue,
} from "../enum/tosAlertChannel";
import PeriodUnit from "../enum/periodUnit";
import FeeMandatoryType, {
    toHumanValue as feeMandatoryTypeToHumanValue,
} from "../enum/feeMandatoryType";
import PriceType, {
    toHumanValue as priceTypeToHumanValue,
} from "../enum/priceType";
import Customer, {
    toHumanValue as customerToHumanValue,
} from "../enum/customer";
import FeeType, { toHumanValue as feeTypeToHumanValue } from "../enum/feeType";
import { Association, ProductVersion, SalesNetwork } from "../types";

import classNames from "classnames";
import ProductType, {
    toHumanValue as productTypeToHumanValue,
} from "../enum/productType";
import { ReactNode } from "react";
import { capitalise, stringWithPeriodUnit } from "../util/string";
import { format as formatNumber, suffixAndNaN } from "../util/number";
import {
    networkName,
    showKwPrice,
    showPurchaseKwPrice,
} from "../util/versionTools";

const MappedField = ({
    prev,
    current,
    defaultValue = null,
    type = "string",
    prevUnit,
    currUnit,
    title,
    prevVersion = undefined,
    currVersion = undefined,
}: {
    title: string;
    prev: unknown;
    current: unknown;
    defaultValue?: null | string;
    type?:
        | "date"
        | "boolean"
        | "string"
        | "link"
        | "ore"
        | "kr"
        | "currencyInOre"
        | "TOSAlertChannel"
        | "FeeType"
        | "Customer"
        | "PeriodUnit"
        | "ProductType"
        | "FeeMandatoryType"
        | "PriceType"
        | "Associations"
        | "SalesNetworks"
        | "PaymentType"
        | "agreementTime"
        | "billingFrequency"
        | "addonPriceMinimumFixedFor"
        | "stringUnit";

    prevUnit?: PeriodUnit;
    currUnit?: PeriodUnit;
    prevVersion?: ProductVersion;
    currVersion?: ProductVersion;
}) => {
    if (type === "string") {
        prev = capitalise(String(prev || "N/A"));
        current = capitalise(String(current || "N/A"));
    }

    if (type === "TOSAlertChannel") {
        prev = tosAlertChannelToHumanValue(prev as TOSAlertChannel) || "N/A";
        current =
            tosAlertChannelToHumanValue(current as TOSAlertChannel) || "N/A";
    }

    if (type === "FeeMandatoryType") {
        prev = feeMandatoryTypeToHumanValue(prev as FeeMandatoryType) || "N/A";
        current =
            feeMandatoryTypeToHumanValue(current as FeeMandatoryType) || "N/A";
    }

    if (type === "ProductType") {
        prev = productTypeToHumanValue(prev as ProductType) || "N/A";
        current = productTypeToHumanValue(current as ProductType) || "N/A";
    }

    if (type === "PriceType") {
        prev = priceTypeToHumanValue(prev as PriceType) || "N/A";
        current = priceTypeToHumanValue(current as PriceType) || "N/A";
    }

    if (type === "Customer") {
        prev =
            customerToHumanValue(prev as Customer, true)?.join(", ") || "N/A";
        current =
            customerToHumanValue(current as Customer, true)?.join(", ") ||
            "N/A";
    }

    if (type === "date") {
        prev = isValid(new Date(prev as string))
            ? format(new Date(prev as string), "dd.MM.yyyy")
            : "N/A";
    }
    if (type === "date") {
        current = isValid(new Date(current as string))
            ? format(new Date(current as string), "dd.MM.yyyy")
            : "N/A";
    }

    if (type === "boolean") {
        prev = prev !== undefined ? (prev ? "Ja" : "Nei") : "N/A";
        current = current !== undefined ? (current ? "Ja" : "Nei") : "N/A";
    }

    if (type === "currencyInOre") {
        prev = Number(prev) / 100;
        current = Number(current) / 100;
        type = "ore";
    }

    if (type === "billingFrequency") {
        prev =
            prev !== undefined
                ? prev === 1 && prevUnit === PeriodUnit.Month
                    ? "Hver måned (anbefalt)"
                    : prev === 2 && prevUnit === PeriodUnit.Month
                    ? "Annen hver måned"
                    : (prev === 12 && prevUnit === PeriodUnit.Month) ||
                      (prev === 1 && prevUnit === PeriodUnit.Year)
                    ? "Èn gang i året"
                    : "N/A"
                : "N/A";
        current =
            current !== undefined
                ? current === 1 && currUnit === PeriodUnit.Month
                    ? "Hver måned (anbefalt)"
                    : current === 2 && currUnit === PeriodUnit.Month
                    ? "Annen hver måned"
                    : (current === 12 && currUnit === PeriodUnit.Month) ||
                      (current === 1 && currUnit === PeriodUnit.Year)
                    ? "Èn gang i året"
                    : "N/A"
                : "N/A";
    }

    if (type === "FeeType") {
        prev = feeTypeToHumanValue(prev as FeeType) || "N/A";
        current = feeTypeToHumanValue(current as FeeType) || "N/A";
    }

    if (type === "ore") {
        prev =
            prev !== null && !isNaN(prev as number)
                ? `${formatNumber(prev as number, 4)} øre`
                : "N/A";
        current =
            current !== null && !isNaN(current as number)
                ? `${formatNumber(current as number, 4)} øre`
                : "N/A";
    }

    if (type === "kr") {
        prev = prev ? `${formatNumber(prev as number, 4)} kr` : "N/A";
        current = current ? `${formatNumber(current as number, 4)} kr` : "N/A";
    }

    if (type === "stringUnit") {
        prev = stringWithPeriodUnit(prev as string, prevUnit as PeriodUnit);
        current = stringWithPeriodUnit(
            current as string,
            currUnit as PeriodUnit,
        );
    }

    if (type === "link") {
        prev = prev ? (
            <Link to={current as string} target="_blank">
                {current as string}
            </Link>
        ) : (
            "N/A"
        );
        current = current ? (
            <Link to={current as string} target="_blank">
                {current as string}
            </Link>
        ) : (
            "N/A"
        );
    }

    if (type === "Associations") {
        if (typeof prev === "object") {
            prev =
                (prev as { association: Association }[])
                    .map((association) => association.association.name)
                    .join(", ") || "Ingen";
        } else {
            prev = "Ingen";
        }

        if (typeof current === "object") {
            current =
                (current as { association: Association }[])
                    .map((association) => association.association.name)
                    .join(", ") || "Ingen";
        } else {
            current = "Ingen";
        }
    }

    if (type === "SalesNetworks") {
        if (typeof prev === "object") {
            prev =
                (prev as SalesNetwork[])
                    .map(
                        (salesNetwork) =>
                            `${networkName(salesNetwork)} ${
                                showKwPrice(
                                    prevVersion?.productType as ProductType,
                                    prevVersion?.priceType as PriceType,
                                )
                                    ? ` (${suffixAndNaN(
                                          salesNetwork.kwPrice,
                                          " kr",
                                          [4],
                                      )})`
                                    : ""
                            }${
                                showPurchaseKwPrice(
                                    prevVersion?.productType as ProductType,
                                    prevVersion?.purchasePriceType as PriceType,
                                )
                                    ? `, kjøp (${suffixAndNaN(
                                          salesNetwork.purchaseKwPrice,
                                          " kr",
                                          [4],
                                      )})`
                                    : ""
                            }`,
                    )
                    .join(", ") || "Ingen";
        } else {
            current = "Ingen";
        }

        if (typeof current === "object") {
            current =
                (current as SalesNetwork[])
                    .map(
                        (salesNetwork) =>
                            `${networkName(salesNetwork)} ${
                                showKwPrice(
                                    currVersion?.productType as ProductType,
                                    currVersion?.priceType as PriceType,
                                )
                                    ? ` (${suffixAndNaN(
                                          salesNetwork.kwPrice,
                                          " kr",
                                          [4],
                                      )})`
                                    : ""
                            }${
                                showPurchaseKwPrice(
                                    currVersion?.productType as ProductType,
                                    currVersion?.purchasePriceType as PriceType,
                                )
                                    ? `, kjøp (${suffixAndNaN(
                                          salesNetwork.purchaseKwPrice,
                                          " kr",
                                          [4],
                                      )})`
                                    : ""
                            }`,
                    )
                    .join(", ") || "Ingen";
        } else {
            current = "Ingen";
        }
    }

    if (defaultValue && !prev) {
        prev = defaultValue;
    }
    if (defaultValue && !current) {
        current = defaultValue;
    }

    return (
        <div className={classNames(["item-subtable-content-row"])}>
            {type !== "link" ? (
                <>
                    <div className="field">{title}:</div>

                    <div className={classNames(["value-old"])}>
                        {String(prev).length ? String(prev) : "N/A"}
                    </div>

                    <div className={classNames(["value-new"])}>
                        {String(current).length ? String(current) : "N/A"}{" "}
                    </div>
                </>
            ) : (
                <>
                    <div className="field">{title}:</div>

                    <div className={classNames(["value-old"])}>
                        {prev as ReactNode}
                    </div>

                    <div className={classNames(["value-new"])}>
                        {current as ReactNode}
                    </div>
                </>
            )}
        </div>
    );
};

export default MappedField;
