import classNames from "classnames";
import "./Timeline.scss";

const Timeline = ({
    steps,
    currentStep,
    errors,
    setStep,
}: {
    steps: string[];
    currentStep: number;
    errors?: number[];
    setStep?: (num: number) => void;
}) => {
    return (
        <div className="timeline__datafanger mb-8 Stepper-root">
            {steps.map((step, idx) => (
                <div
                    key={idx}
                    className={classNames("timeline-step Step-root", {
                        "current-step": idx === currentStep - 1,
                        completed: idx < currentStep - 1,
                        error: errors?.includes(idx + 1),
                    })}
                >
                    {idx > 0 && (
                        <div className="StepConnector-root">
                            <span className="StepConnector-line"></span>
                        </div>
                    )}
                    <button
                        type="button"
                        className="StepButton-root"
                        onClick={() => {
                            setStep && setStep(idx);
                        }}
                    >
                        <span className="StepLabel-root">
                            <span className="StepLabel-iconContainer">
                                <i
                                    className={classNames(
                                        "fa-sharp fa-regular",
                                        {
                                            "fa-check": idx < currentStep - 1,
                                            "": idx === currentStep - 1,
                                        },
                                    )}
                                ></i>
                            </span>
                            <span className="StepLabel-labelContainer">
                                <span className="StepLabel-label">{step}</span>
                            </span>
                        </span>
                    </button>
                </div>
            ))}
        </div>
    );
};

export default Timeline;
