/**
 * Shortend event.stopPropagation()
 * @example <div onClick={stopPropagation}></div>
 * @param event
 */
const stopPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
};

export { stopPropagation };
