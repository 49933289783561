/**
 * converts billingFrequency time intervals to norvegian text
 * @param months period in months
 */

const billingFrequencyToText = (
    months: number,
):
    | "Ikke oppgitt"
    | "Hver måned (anbefalt)"
    | "Annen hver måned"
    | "Sjeldnere enn annen hver måned"
    | "Hvert halvår"
    | "Én gang i året"
    | "Annen hvert år"
    | "Sjeldnere enn annen hvert år" =>
    months === 0
        ? "Ikke oppgitt"
        : months === 1
        ? "Hver måned (anbefalt)"
        : months === 2
        ? "Annen hver måned"
        : months < 6
        ? "Sjeldnere enn annen hver måned"
        : months === 6
        ? "Hvert halvår"
        : months > 6 && months < 24
        ? "Én gang i året"
        : months >= 24 && months < 36
        ? "Annen hvert år"
        : "Sjeldnere enn annen hvert år";

export { billingFrequencyToText };
