import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { isFuture } from "date-fns";
import axios from "../util/axios";
import type { SalesRegion } from "../types";
import { useStore } from ".";

export default function useSalesRegions() {
    const { authToken } = useStore();
    const retrieveSalesRegions = async (): Promise<SalesRegion[]> => {
        const response: AxiosResponse<SalesRegion[]> =
            await axios.get("sales_regions");

        return response.data;
    };

    return useQuery({
        queryFn: retrieveSalesRegions,
        queryKey: ["salesRegions"],
        retry: 3,
        enabled: authToken !== null && isFuture(new Date(authToken.expiresAt)),
    });
}
