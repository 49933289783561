import classNames from "classnames";
const Alert = ({
    type = "info",
    mt = null,
    message,
}: {
    type?: "success" | "warning" | "info" | "danger";
    mt?: null | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    message: string;
}) => {
    return (
        <div
            className={classNames("alert", [
                `alert-${type}`,
                mt !== null && `mt-${mt}`,
            ])}
        >
            <i className="fa-regular fa-circle-exclamation" />
            <span className="ml-3">{message}</span>
        </div>
    );
};

export default Alert;
