import { useStore } from "../../hooks";

export default function ModalHandler() {
    const [currentModal, modalEnabled, setModalEnabled] = useStore((state) => [
        state.currentModal,
        state.modalEnabled,
        state.setModalEnabled,
    ]);

    if (!modalEnabled) {
        return null;
    }

    return (
        <div
            style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                backgroundColor: "#00000085",
                zIndex: "1111",
                display: "flex",
            }}
            onClick={() => {
                setModalEnabled(!modalEnabled);
            }}
        >
            {currentModal}
        </div>
    );
}
