import { addYears, isFuture, format, isPast } from "date-fns";
import { nb } from "date-fns/locale";
import PriceType from "../enum/priceType";
import ProductType from "../enum/productType";
import PublishingStatus from "../enum/publishingStatus";
import { Product, ProductVersion, SalesNetwork } from "../types";

// to check if product is active
const isActive = (version: ProductVersion): boolean => {
    const versionActiveFrom = activeFrom(version);
    const versionDeletedAt = deletedAt(version);

    const isEnabled = versionDeletedAt
        ? isFuture(versionDeletedAt)
            ? true
            : false
        : true;

    const isActive = isPast(versionActiveFrom) && isEnabled;

    const isPublished = version.publishingStatus === PublishingStatus.Published;

    return isPublished && isActive;
};

// to check if version is latest version in product (don't need to be in product)
const isLatest = (version: ProductVersion, product: Product): boolean => {
    const versionId = version.id;
    const latest = product.versions.find(
        (version) =>
            version.publishingStatus !== PublishingStatus.Draft &&
            !isFuture(activeFrom(version)),
    );
    return versionId === latest?.id;
};

const isExpired = (version: ProductVersion): boolean =>
    version?.expiredAt !== null &&
    version?.expiredAt !== undefined &&
    !isFuture(new Date(version?.expiredAt));

const deletedAt = (version: ProductVersion): Date | undefined => {
    if (version.deletedAt === null || version.deletedAt === undefined) {
        return undefined;
    }

    return new Date(version.deletedAt || 0);
};

// when is product activated
const activeFrom = (version: ProductVersion): Date =>
    // assumes that no product version is created 10+ years ahead
    new Date(
        version.publishedAt || version.createdAt || addYears(new Date(), 10),
    );

// text for possible status for a product version
const status = (product: Product, version: ProductVersion): string => {
    if (version.publishingStatus === PublishingStatus.Draft) {
        // product is draft
        return "Utkast";
    }

    if (product.deletedAt !== null || version.deletedAt !== null) {
        // product has been deleted
        return "Slettet";
    }

    if (
        product.activatedAt === null ||
        version.publishingStatus === PublishingStatus.Deactivated
    ) {
        return "Deaktivert";
    }

    if (version.publishingStatus === PublishingStatus.Pending) {
        // pending approval from admin
        return "Venter godkjenning";
    }

    if (version.publishingStatus === PublishingStatus.Rejected) {
        // rejected by super admin
        return "Ikke godkjent";
    }

    if (isLatest(version, product) && isActive(version)) {
        // published, latest active and not expired
        return "Aktiv";
    }

    if (isActive(version)) {
        // published but not latest active
        return "Deaktivert";
    }

    if (
        version.publishingStatus === PublishingStatus.Approved ||
        (version.publishingStatus === PublishingStatus.Published &&
            isFuture(activeFrom(version)))
    ) {
        // Approved
        return "Godkjent";
    }

    return "Ukjent status";
};

const coloredStatus = (
    product: Product,
    version: ProductVersion,
): "green" | "red" | "yellow" => {
    const mappedColors: { [key: string]: "green" | "red" | "yellow" } = {
        Aktiv: "green",
        Deaktivert: "red",
        "Venter godkjenning": "yellow",
        Godkjent: "yellow",
        "Ikke Godkjent": "red",
        Utkast: "green",
        "Deaktivert utkast": "red",
        Slettet: "red",
        "Ukjent status": "red",
    };

    return (
        mappedColors[status(product, version)] || mappedColors["Ukjent status"]
    );
};

const canDeactivateProductVersion = (product: Product) =>
    product.versions.filter(
        (version) => version.publishingStatus !== PublishingStatus.Draft,
    ).length > 0;

const statusIcon = (product: Product, version: ProductVersion): string => {
    const mappedIcons: { [key: string]: string } = {
        Aktiv: "fa-circle-check approved",
        Deaktivert: "fa-ban rejected",
        "Venter godkjenning": "fa-hourglass-clock waiting",
        Godkjent: "fa-circle-check waiting",
        "Ikke Godkjent": "fa-ban rejected",
        Utkast: "fa-file-pen",
        "Deaktivert utkast": "fa-file-pen rejected",
        Slettet: "fa-trash-can rejected",
        "Ukjent status": "fa-circle-question rejected",
    };

    return (
        mappedIcons[status(product, version)] || mappedIcons["Ukjent status"]
    );
};

const hasSurcharge = (productType: ProductType, priceType: PriceType) =>
    productType === ProductType.Spot ||
    productType === ProductType.Purchase ||
    productType === ProductType.HourlySpot ||
    (productType === ProductType.Plus && priceType === PriceType.Spot) ||
    (productType === ProductType.Other && priceType === PriceType.Spot);

const hasPurchaseSurcharge = (productType: ProductType, priceType: PriceType) =>
    productType === ProductType.Plus && priceType === PriceType.Spot;

const showPriceType = (productType: ProductType) =>
    productType === ProductType.Plus || productType === ProductType.Other;

const showPurchasePriceType = (productType: ProductType) =>
    productType === ProductType.Plus;

const showKwPrice = (productType: ProductType, priceType: PriceType) =>
    productType === ProductType.Fixed ||
    productType === ProductType.Variable ||
    (productType === ProductType.Other && priceType === PriceType.Kilowatt) ||
    (productType === ProductType.Plus && priceType === PriceType.Kilowatt);

const showPurchaseKwPrice = (productType: ProductType, priceType: PriceType) =>
    productType === ProductType.Plus && priceType === PriceType.Kilowatt;

const lastModifiedBy = (version: ProductVersion) => {
    if (version.updater && version.updater.name) {
        return version.updater.name;
    }

    if (version.updater && version.updater.email) {
        return version.updater.email;
    }

    if (version.creator && version.creator.name) {
        return version.creator.name;
    }

    if (version.creator && version.creator.email) {
        return version.creator.email;
    }

    return "Ukjent";
};

const lastModifiedAt = (version: Product | ProductVersion) => {
    let updatedAt = version.updatedAt;
    if ("versions" in version) {
        const productDates = [
            ...version.versions.map((version) => version.updatedAt),
            version.updatedAt,
        ].filter((date) => !!date);

        updatedAt =
            productDates.length > 0
                ? new Date(
                      Math.max(
                          ...productDates.map((date) =>
                              new Date(date as string).getTime(),
                          ),
                      ),
                  )
                : null;
    }
    return updatedAt
        ? format(new Date(updatedAt), "d. LLL yyyy 'kl.' HH:mm", {
              locale: nb,
          })
        : "N/A";
};

const modifiedViaApi = (version: ProductVersion): boolean =>
    (!!version.importedCreatedBy || !!version.importedUpdatedBy) &&
    !version.createdBy &&
    !version.updatedBy;

const applyFilters = (
    product: Product,
    search: string,
    filter: "all" | "activated" | "deactivated" | "deleted" | "draft",
) => {
    const isActive =
        product.activatedAt &&
        !product.deletedAt &&
        (product.versions || []).filter(
            (version) =>
                version.publishingStatus === PublishingStatus.Published &&
                version.deletedAt === null,
        ).length > 0 &&
        !isFuture(new Date(product.activatedAt as unknown as string));

    return (
        (!search ||
            product.name
                .toLocaleLowerCase("nb")
                .includes(search.toLocaleLowerCase("nb")) ||
            !search ||
            product.organization.name
                .toLocaleLowerCase("nb")
                .includes(search.toLocaleLowerCase("nb"))) &&
        (filter === "all" ||
            (filter === "activated" && isActive) ||
            (filter === "deactivated" &&
                !product.activatedAt &&
                !product.deletedAt) ||
            (filter === "deleted" && product.deletedAt) ||
            (filter === "draft" &&
                product.versions.filter(
                    (version) =>
                        version.publishingStatus === PublishingStatus.Draft,
                ).length > 0 &&
                product.deletedAt === null))
    );
};

const networkName = (network?: SalesNetwork): string => {
    return (
        network?.municipality?.name ||
        network?.area?.name ||
        network?.county?.name ||
        (network?.isNorwegian && "Norge") ||
        "N/A"
    );
};

export {
    isLatest as isLatestActive,
    isActive,
    isExpired,
    deletedAt,
    activeFrom,
    status,
    statusIcon,
    coloredStatus,
    hasSurcharge,
    hasPurchaseSurcharge,
    showKwPrice,
    showPurchaseKwPrice,
    showPriceType,
    showPurchasePriceType,
    lastModifiedAt,
    lastModifiedBy,
    modifiedViaApi,
    applyFilters,
    networkName,
    canDeactivateProductVersion,
};
