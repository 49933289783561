import PeriodUnit from "../enum/periodUnit";

const lpad = (n: number, paddedZeros: number = 0): string =>
    `${"0".repeat(paddedZeros)}${String(n)}`.slice(paddedZeros * -1);

/**
 * @param string
 * @description capitalise the first letter in a string
 */
const capitalise = (string: string | undefined | null): string => {
    return string
        ? string.slice(0, 1).toUpperCase() + string.slice(1, string.length)
        : "";
};

/**
 * adds a sufix to a string if the sting is not "Ikke oppgitt"
 * @param string string to add the sufix to
 * @param sufix sufix to add to sting
 * @returns `${string}${sufix}` | `"Ikke oppgitt"`
 */
const stringSuffix = (string: string | number, sufix: string) =>
    string === "Ikke oppgitt" ? String(string) : `${string}${sufix}`;

const parseUrl = (url: string, prepend: boolean = true) => {
    // Allow empty strings without prepending 'https://'
    if (url === "") {
        return "";
    }

    if (!/^https?:\/\//i.test(url) && prepend) {
        return `https://${url}`;
    }

    return url;
};

const stringWithPeriodUnit = (string: string, unit: PeriodUnit) => {
    if (
        unit === PeriodUnit.Day &&
        string !== undefined &&
        string !== null &&
        Number(string) === 1
    ) {
        return `${string} dag`;
    }

    if (
        unit === PeriodUnit.Day &&
        string !== undefined &&
        string !== null &&
        Number(string) !== 1 &&
        Number(string) !== 0
    ) {
        return `${string} dager`;
    }

    if (
        unit === PeriodUnit.Month &&
        string !== undefined &&
        string !== null &&
        Number(string) === 1
    ) {
        return `${string} måned`;
    }

    if (
        unit === PeriodUnit.Month &&
        string !== undefined &&
        string !== null &&
        Number(string) !== 1 &&
        Number(string) !== 0
    ) {
        return `${string} måneder`;
    }

    if (
        unit === PeriodUnit.Year &&
        string !== undefined &&
        string !== null &&
        Number(string) === 1
    ) {
        return `${string} år`;
    }

    if (
        unit === PeriodUnit.Year &&
        string !== undefined &&
        string !== null &&
        Number(string) !== 1 &&
        Number(string) !== 0
    ) {
        return `${string} år`;
    }

    return "Ingen";
};

export { lpad, capitalise, stringSuffix, parseUrl, stringWithPeriodUnit };
