import axios from "axios";

function getApiHost() {
    const env = process.env?.REACT_APP_ENVIRONMENT || "local";
    const apiUrl = process.env.REACT_APP_API_URL;

    if (env === "local") {
        return apiUrl || "http://localhost:3000";
    }

    if (env !== "production") {
        return apiUrl || "https://strom-api.forbrukerhelt.no";
    }

    return apiUrl || "https://strom-api.forbrukerradet.no";
}

const axiosInstance = axios.create({
    baseURL: getApiHost(),
    headers: {
        common: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    },
});

export default axiosInstance;
