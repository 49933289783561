import { format as formatDate } from "date-fns";
import { Product, ProductVersion } from "../types";
import { periodSuffix, periodToMonths } from "../enum/periodUnit";
import FeeMandatoryType from "../enum/feeMandatoryType";
import Customer from "../enum/customer";
import PriceType, {
    toHumanValue as priceTypeToHumanValue,
} from "../enum/priceType";
import ProductType, {
    toHumanValue as productTypeToHumanValue,
} from "../enum/productType";
import TOSAlertChannel, {
    toHumanValue as tosAlertChannelToHumanValue,
} from "../enum/tosAlertChannel";
import FeeType, { toHumanValue as feeTypeToHumanValue } from "../enum/feeType";
import { billingFrequencyToText } from "../util/billingFrequency";
import { capitalise } from "../util/string";
import { numExists, suffixAndNaN } from "../util/number";
import {
    status,
    hasSurcharge,
    hasPurchaseSurcharge,
    showKwPrice,
    showPriceType,
    showPurchaseKwPrice,
    showPurchasePriceType,
} from "../util/versionTools";
import { HTMLAttributes } from "react";
import style from "./ProductSummary.module.scss";
import classNames from "classnames";

/**
 * Builds the product summary item
 * @param version ProductVersion that is displayed
 * @param product Required to show product status
 * @param ``This component suports any HTMLAttributes that is common across all HTML components
 * @returns React Function component
 */
const ProductSummary: React.FC<
    HTMLAttributes<HTMLElement> & {
        version: ProductVersion;
        product?: Product;
    }
> = ({ version, product }, ...rest) => {
    const billingFrequencyInMonths = periodToMonths(
        version.billingFrequency,
        version.billingFrequencyUnit,
    );

    return (
        <div className={classNames(style.ProductSummary, "mt-6")} {...rest}>
            <h2
                className={classNames(
                    style.ProductSummary_row,
                    style.ProductSummary_row_first,
                )}
            >
                Info om avtale
            </h2>
            <div className={style.ProductSummary_row}>
                <span>Navn på avtale</span>
                <span>{capitalise(version?.name || "")}</span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Avtaletype</span>
                <span>
                    {productTypeToHumanValue(
                        version?.productType as ProductType,
                    )}
                </span>
            </div>
            {version?.expiredAt === null && (
                <div className={style.ProductSummary_row}>
                    <span>URL</span>
                    <span>
                        <a href={version?.orderUrl as string} target="_blank">
                            {version?.orderUrl}
                        </a>
                    </span>
                </div>
            )}
            <div className={style.ProductSummary_row}>
                <span>Utgått</span>
                <span>{version?.expiredAt !== null ? "Ja" : "Nei"}</span>
            </div>
            {version?.publishingStatus && product && (
                <div className={style.ProductSummary_row}>
                    <span>Status</span>
                    <span>{status(product, version)}</span>
                </div>
            )}
            <h2 className={style.ProductSummary_row}>Priser</h2>
            {showPriceType(version.productType as ProductType) && (
                <div className={style.ProductSummary_row}>
                    <span>Prismodell</span>
                    <span>
                        {priceTypeToHumanValue(version.priceType as PriceType)}
                    </span>
                </div>
            )}
            {showPurchasePriceType(version.productType as ProductType) && (
                <div className={style.ProductSummary_row}>
                    <span>Prismodell (kjøp)</span>
                    <span>
                        {priceTypeToHumanValue(
                            version.purchasePriceType as PriceType,
                            true,
                        )}
                    </span>
                </div>
            )}
            <div className={style.ProductSummary_row}>
                <span>Salgsregion</span>
                <span>
                    {version?.salesNetworks
                        .map(
                            (salesRegion) =>
                                `${
                                    salesRegion.isNorwegian
                                        ? "Norge"
                                        : salesRegion.area
                                        ? `${salesRegion.area.name} (${salesRegion.area.code})`
                                        : salesRegion.county
                                        ? salesRegion.county.name
                                        : salesRegion.municipality
                                        ? salesRegion.municipality.name
                                        : "N/A"
                                }${
                                    showKwPrice(
                                        version.productType as ProductType,
                                        version.priceType as PriceType,
                                    )
                                        ? ` (${suffixAndNaN(
                                              salesRegion.kwPrice,
                                              " kr",
                                              [4],
                                          )})`
                                        : ""
                                }${
                                    showPurchaseKwPrice(
                                        version.productType as ProductType,
                                        version.purchasePriceType as PriceType,
                                    )
                                        ? `, kjøp (${suffixAndNaN(
                                              salesRegion.purchaseKwPrice,
                                              " kr",
                                              [4],
                                          )})`
                                        : ""
                                }`,
                        )
                        .join(", ")}
                </span>
            </div>
            {hasSurcharge(
                version.productType as ProductType,
                version.priceType as PriceType,
            ) &&
                version.addonPrice !== undefined && (
                    <div className={style.ProductSummary_row}>
                        <span>Påslag per kWt inkl. elsertifikatpris</span>
                        <span>
                            {suffixAndNaN(version.addonPrice, " øre", [4])}
                        </span>
                    </div>
                )}
            {hasPurchaseSurcharge(
                version.productType as ProductType,
                version.purchasePriceType as PriceType,
            ) &&
                version.purchaseAddonPrice !== undefined && (
                    <div className={style.ProductSummary_row}>
                        <span>Påslag kjøpspris</span>
                        <span>
                            {suffixAndNaN(version.purchaseAddonPrice, " øre", [
                                4,
                            ])}
                        </span>
                    </div>
                )}
            <div className={style.ProductSummary_row}>
                <span>El-sertifikatrabatt</span>
                <span>
                    {suffixAndNaN(Number(version?.elCertificatePrice), " øre", [
                        4,
                    ])}
                </span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Månedlig avgift</span>
                <span>{suffixAndNaN(version?.monthlyFee, " kr", [4])}</span>
            </div>
            <h2 className={style.ProductSummary_row}>Varighet på avtale</h2>
            <div className={style.ProductSummary_row}>
                <span>
                    Hvor lenge varer avtalen med uforandret pris og vilkår?
                </span>
                <span>
                    {version?.addonPriceMinimumFixedFor !== 0 &&
                    numExists(version?.addonPriceMinimumFixedFor) &&
                    numExists(version?.addonPriceMinimumFixedForUnit)
                        ? `${version?.addonPriceMinimumFixedFor} ${periodSuffix(
                              version?.addonPriceMinimumFixedFor || 0,
                              1,
                          )}`
                        : "Ikke oppgitt"}
                </span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Bindingstid på avtalen</span>
                <span>
                    {version?.agreementTime !== 0 &&
                    numExists(version?.agreementTime) &&
                    numExists(version?.agreementTimeUnit)
                        ? `${version?.agreementTime} ${periodSuffix(
                              version?.agreementTime || 0,
                              version?.agreementTimeUnit || 0,
                          )}`
                        : "Ingen"}
                </span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Bruddgebyr</span>
                <span>{suffixAndNaN(version?.feeContractBreach, " kr")}</span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Kunder blir fakturert</span>
                <span>
                    {numExists(version.paymentType)
                        ? feeTypeToHumanValue(version.paymentType as FeeType)
                        : "Ikke oppgitt"}
                </span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Fakturaintervall</span>
                <span>
                    {suffixAndNaN(
                        billingFrequencyInMonths,
                        "",
                        [],
                        billingFrequencyToText,
                    )}
                </span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Er e-faktura påkrevd for å få denne avtalen?</span>
                <span>
                    {[
                        FeeMandatoryType.ElectronicInvoice,
                        FeeMandatoryType.Both,
                    ].includes(version?.feeMandatoryType as FeeMandatoryType)
                        ? "Ja"
                        : "Nei"}
                </span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Avgift for papirfaktura</span>
                <span>
                    {version.feePostalLetter !== null
                        ? suffixAndNaN(version?.feePostalLetter, " kr", [4])
                        : "Ikke oppgitt"}
                </span>
            </div>
            <h2 className={style.ProductSummary_row}>Andre betingelser</h2>
            <div className={style.ProductSummary_row}>
                <span>For hvilke kundetyper gjelder denne avtalen?</span>
                <span>
                    {[Customer.New, Customer.NewWithMembership].includes(
                        version?.applicableToCustomerType as Customer,
                    )
                        ? "Nye kunder"
                        : [
                              Customer.Existing,
                              Customer.ExistingWithMembership,
                          ].includes(
                              version?.applicableToCustomerType as Customer,
                          )
                        ? "Eksisterende kunder"
                        : "Alle kunder"}
                </span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Er dette en hytteavtale?</span>
                <span>{version?.cabinProduct ? "Ja" : "Nei"}</span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Har avtalen et tak for maksimalt kWt-forbruk?</span>
                <span>
                    {version?.maxKwhPerYear !== 0 &&
                    version?.maxKwhPerYear !== undefined
                        ? "Ja"
                        : "Nei"}
                </span>
            </div>
            {version?.maxKwhPerYear !== 0 &&
                version?.maxKwhPerYear !== undefined && (
                    <div className={style.ProductSummary_row}>
                        <span>Maksimalt kWt-forbruk i året</span>
                        <span>{version?.maxKwhPerYear} kWt</span>
                    </div>
                )}
            <div className={style.ProductSummary_row}>
                <span>
                    Standardmåte for varsling av endringer i pris/vilkår
                </span>
                <span>
                    {tosAlertChannelToHumanValue(
                        version?.standardAlert as TOSAlertChannel,
                    )}
                </span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Krever medlemskap</span>
                <span>
                    {version?.requiresMembership &&
                    version?.associations?.length
                        ? version.associations
                              .map((item) => item.name)
                              .join(", ")
                        : "Ingen"}
                </span>
            </div>
            <div className={style.ProductSummary_row}>
                <span>Andre betingelser</span>
                <span>{version?.otherConditions || "Ingen"}</span>
            </div>
            <div
                className={classNames(
                    style.ProductSummary_row,
                    style.ProductSummary_row_last,
                )}
            >
                <span>Avtalen er aktiv fra og med</span>
                <span>
                    {version?.publishedAt
                        ? formatDate(
                              new Date(version?.publishedAt),
                              "dd.MM.yyyy",
                          )
                        : "Ikke oppgitt"}
                </span>
            </div>
        </div>
    );
};
export default ProductSummary;
