import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { isFuture } from "date-fns";
import axios from "../util/axios";
import type { DbProduct, Product } from "../types";
import { DbVersionToEnumValue } from "../util/productVersion";
import { useStore } from ".";

export default function useProducts(slug: string | null = null) {
    const { authToken } = useStore();
    const retrieveProducts = async (
        slug: string | null,
    ): Promise<Product[]> => {
        const response: AxiosResponse<DbProduct[]> = await axios.get(
            `products${slug ? `?slug=${encodeURIComponent(slug)}` : ""}`,
        );

        const resp: AxiosResponse<Product[], Product[]> = {
            ...response,
            data: response.data.map((p) => ({
                ...p,
                versions: p.versions.map((v) => DbVersionToEnumValue(v)),
            })),
        };

        return resp.data;
    };

    return useQuery(
        ["products", slug],
        async () => await retrieveProducts(slug),
        {
            retry: 3,
            enabled:
                authToken !== null && isFuture(new Date(authToken.expiresAt)),
        },
    );
}
