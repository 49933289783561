import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { isFuture } from "date-fns";
import axios from "../util/axios";
import type { User } from "../types";
import { useStore } from ".";

export default function useUsers() {
    const { authToken } = useStore();
    const retrieveUsers = async (): Promise<User[]> => {
        const response: AxiosResponse<User[]> = await axios.get("users");

        return response.data;
    };

    return useQuery("users", async () => await retrieveUsers(), {
        retry: 3,
        enabled: authToken !== null && isFuture(new Date(authToken.expiresAt)),
    });
}
