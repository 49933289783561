import { useQuery } from "react-query";
import useStore from "./useStore";
import { differenceInMilliseconds, isFuture } from "date-fns";
import axios from "../util/axios";
import { AxiosResponse } from "axios";
import { GrantType } from "../enum";
import type { ExternalAuthToken } from "./useStore";
export default function useAuth() {
    const { authToken, setAuthToken, setUser } = useStore();

    if (authToken !== null) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `${authToken.tokenType} ${authToken.accessToken}`;
    }

    const retrieveAuthData = async (): Promise<{
        token: ExternalAuthToken | null;
        email: string;
    }> => {
        if (authToken === null) {
            return {
                token: null,
                email: "",
            };
        }

        if (isFuture(new Date(authToken.expiresAt))) {
            return {
                token: {
                    accessToken: authToken.accessToken,
                    refreshToken: authToken.refreshToken,
                    tokenType: authToken.tokenType,
                    expiresIn: differenceInMilliseconds(
                        new Date(),
                        new Date(authToken.expiresAt),
                    ),
                    refreshTokenExpiresIn: differenceInMilliseconds(
                        new Date(),
                        new Date(authToken.refreshExpiresAt),
                    ),
                },
                email: authToken.email,
            };
        }

        if (!isFuture(new Date(authToken.refreshExpiresAt))) {
            delete axios.defaults.headers.common["Authorization"];
            setAuthToken("", null);
            setUser(null);
            return {
                token: null,
                email: "",
            };
        }

        // Refresh token
        const response: AxiosResponse<ExternalAuthToken> = await axios.post(
            "auth/token",
            {
                grantType: GrantType.RefreshToken,
                email: authToken.email,
                refreshToken: authToken.refreshToken,
            } as {
                grantType: string;
                email: string;
                refreshToken: string;
            },
        );

        return {
            token: {
                ...response.data,
                expiresIn: response.data.expiresIn * 1000,
                refreshTokenExpiresIn:
                    response.data.refreshTokenExpiresIn * 1000,
            },
            email: authToken.email,
        };
    };
    return useQuery("auth", async () => await retrieveAuthData(), {
        onSuccess: (token: {
            token: ExternalAuthToken | null;
            email: string;
        }) => {
            if (
                token.token === null ||
                token.token.accessToken === authToken?.accessToken
            ) {
                return;
            }

            axios.defaults.headers.common[
                "Authorization"
            ] = `${token.token.tokenType} ${token.token.accessToken}`;

            setAuthToken(token.email, token.token);
        },
        onError: () => {
            delete axios.defaults.headers.common["Authorization"];
            setAuthToken("", null);
            setUser(null);
        },
        retry: 3,
        refetchInterval: () =>
            Math.min(
                differenceInMilliseconds(
                    new Date(authToken?.expiresAt as Date),
                    new Date(),
                ),
                1000 * 60 * 5,
            ),
        refetchIntervalInBackground: true,
    });
}
