import {
    toEnumValue as CustomerTypeToEnumValue,
    DbtoHumanValue as CustomerToHumanValue,
} from "../enum/customer";
import {
    DbtoEnumValue as FeeMandatoryTypeToEnumValue,
    DbtoHumanValue as FeeMandatoryTypeDbtoHumanValue,
} from "../enum/feeMandatoryType";
import {
    DbtoEnumValue as PaymentTypeToEnumValue,
    DbtoHumanValue as PaymentTypeDbtoHumanValue,
} from "../enum/feeType";
import {
    toEnumValue as PeriodUnitToEnumValue,
    toHumanValue as PeriodUnittoHumanValue,
} from "../enum/periodUnit";
import {
    dbToEnumValue as PriceTypeToEnumValue,
    dbToHumanValue as PriceTypeDbtoHumanValue,
    DbPriceTypeValues,
} from "../enum/priceType";
import {
    dbToEnumValue as ProductTypeToEnumValue,
    dbToHumanValue as ProductTypeDbtoHumanValue,
} from "../enum/productType";
import PublishingStatus, {
    dbToEnumValue as PublishingStatusToEnumValue,
    dbToHumanValue as PublishingStatusDbtoHumanValue,
} from "../enum/publishingStatus";
import {
    dbToEnumValue as TOSToEnumValue,
    dbToHumanValue as TOSDbtoHumanValue,
} from "../enum/tosAlertChannel";
import { DbHumanProductVersion, ProductVersion } from "../types/productVersion";

// converts a version to the format the db expects
const versionToDbHuman = (v: ProductVersion): DbHumanProductVersion => {
    const HumanVersion: DbHumanProductVersion = {
        ...v,
        priceType: PriceTypeDbtoHumanValue(v.priceType as number | null),
        purchasePriceType: PriceTypeDbtoHumanValue(
            v.purchasePriceType as number | null,
        ),
        productType: ProductTypeDbtoHumanValue(v.productType as number | null),
        paymentType: PaymentTypeDbtoHumanValue(v.paymentType as number | null),
        feeMandatoryType: FeeMandatoryTypeDbtoHumanValue(
            v.feeMandatoryType as number | null,
        ),
        agreementTimeUnit: PeriodUnittoHumanValue(
            v.agreementTimeUnit as number | null,
        ),
        billingFrequencyUnit: PeriodUnittoHumanValue(
            v.billingFrequencyUnit as number | null,
        ),
        addonPriceMinimumFixedForUnit: PeriodUnittoHumanValue(
            v.addonPriceMinimumFixedForUnit as number | null,
        ),
        applicableToCustomerType: CustomerToHumanValue(
            v.applicableToCustomerType as number | null,
        ),
        standardAlert: TOSDbtoHumanValue(v.standardAlert as number | null),
        publishingStatus: PublishingStatusDbtoHumanValue(
            v.publishingStatus as PublishingStatus,
        ),
    } as DbHumanProductVersion;

    return HumanVersion;
};

// converts a version from the db to the version admin expects
const DbVersionToEnumValue = (v: DbHumanProductVersion): ProductVersion => {
    const EnumVersion: ProductVersion = {
        ...v,
        priceType: PriceTypeToEnumValue(
            v.priceType as DbPriceTypeValues | null,
        ),
        purchasePriceType: PriceTypeToEnumValue(v.purchasePriceType),
        productType: ProductTypeToEnumValue(v.productType),
        paymentType: PaymentTypeToEnumValue(v.paymentType),
        feeMandatoryType: FeeMandatoryTypeToEnumValue(v.feeMandatoryType),
        agreementTimeUnit: PeriodUnitToEnumValue(v.agreementTimeUnit),
        billingFrequencyUnit: PeriodUnitToEnumValue(v.billingFrequencyUnit),
        addonPriceMinimumFixedForUnit: PeriodUnitToEnumValue(
            v.addonPriceMinimumFixedForUnit,
        ),
        applicableToCustomerType: CustomerTypeToEnumValue(
            v.applicableToCustomerType,
        ),
        standardAlert: TOSToEnumValue(v.standardAlert),
        publishingStatus: PublishingStatusToEnumValue(v.publishingStatus),
    } as ProductVersion;

    return EnumVersion;
};

export { DbVersionToEnumValue, versionToDbHuman };
