import React, { ChangeEvent } from "react";
import classNames from "classnames";
import { suffixAndNaN } from "../util/number";

import "./DynamicField.scss";

const DynamicField = ({
    id,
    className,
    onChange,
    value,
    helper,
    format,
    unit, // 'kr' for kroner, 'ore' for øre
    style,
    min,
    max,
    allowNegative = false,
    disabled = false,
    disabledPlaceholder = "",
}: {
    id?: string;
    value?: string | number;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    helper?: string;
    format?: string;
    unit?: "kr" | "ore";
    style?: React.CSSProperties;
    min?: number;
    max?: number;
    allowNegative?: boolean;
    disabled?: boolean;
    disabledPlaceholder?: string;
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.value === "" ||
            (allowNegative && /^-?\d*(\.|,)?\d*$/.test(e.target.value)) ||
            /^\d*(\.|,)?\d*$/.test(e.target.value)
        ) {
            e.target.value = e.target.value.replace(",", ".");
            onChange(e);
        }
    };

    const formatRightText = (val: string) => {
        if (val && !isNaN(Number(val))) {
            const numberValue = Number(val);

            // Determine if we're using 'kr' or 'ore' and convert accordingly
            if (unit === "kr") {
                // Display how many øre that is (kr to øre)
                return format?.replace(
                    "{value}",
                    suffixAndNaN(numberValue * 100, " øre", [4]),
                );
            } else if (unit === "ore") {
                // Display how many kroner that equals (øre to kr)
                return format?.replace(
                    "{value}",
                    suffixAndNaN(numberValue / 100, " kr", [4]),
                );
            }
        }
        return "";
    };

    return (
        <div
            className={classNames(className, {
                disabled,
            })}
            style={{
                display: "flex",
                alignItems: "center",
                ...style,
            }}
        >
            {!disabled && (
                <span style={{ marginRight: "8px", width: "auto" }}>
                    {helper}
                </span>
            )}

            <input
                id={id}
                type="text"
                value={
                    disabled && disabledPlaceholder
                        ? disabledPlaceholder
                        : value !== undefined
                        ? String(value).replace(".", ",")
                        : undefined
                }
                onChange={handleChange}
                min={min === undefined ? (allowNegative ? -Infinity : 0) : min}
                max={max}
                disabled={disabled || false}
                placeholder={disabled ? disabledPlaceholder : undefined}
            />
            {!disabled && (
                <span style={{ width: "auto" }}>
                    {formatRightText(value as string)}
                </span>
            )}
        </div>
    );
};

export default DynamicField;
