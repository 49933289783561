import { useState } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { Button, FormGroup, LoadingSpinner } from "frno-react";
import classNames from "classnames";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { z, ZodError } from "zod";
import { FieldError } from "./";
import { AuthProfile, Organization } from "../types";
import { Alert, ConfirmationModal } from ".";
import { isAdmin } from "../util/user";
import { useAuthProfiles, useUser } from "../hooks";
import { Error, processErrors } from "../util/validation";
import "./AuthProfiles.scss";

const schema = z.object({
    name: z.string().min(0),
    email: z
        .string({
            errorMap: () => ({
                message:
                    "Du må oppgi en gyldig e-postadresse til den eksterne konsumenten.",
            }),
        })
        .email(),
});

const AuthProfiles = ({
    slug = null,
    organization = null,
}: {
    slug?: string | null;
    organization?: Organization | null;
}) => {
    const [authProfileId, setAuthProfileId] = useState<number | null>(null);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [isFlashing, setIsFlashing] = useState<{ [key: number]: boolean }>(
        {},
    );
    const [isUpdating, setIsUpdating] = useState<{ [key: number]: boolean }>(
        {},
    );
    const [errors, setErrors] = useState<Error>({});
    const [isRegeneratingSecret, setIsRegeneratingSecret] = useState<{
        [key: number]: boolean;
    }>({});
    const [isDeletingAuthProfile, setIsDeletingAuthProfile] = useState<{
        [key: number]: boolean;
    }>({});
    const [hasOpenedDeleteModal, setHasOpenedDeleteModal] = useState<{
        [key: number]: boolean;
    }>({});
    const [error, setError] = useState<string>("");
    const { data: authenticatedUser, isLoading: isLoadingAuthenticatedUser } =
        useUser();
    const {
        data: authProfiles,
        isLoading: isLoadingAuthProfiles,
        createAuthProfile,
        updateAuthProfile,
        deleteAuthProfile,
        regenerateSecret,
    } = useAuthProfiles(slug);
    const queryClient = useQueryClient();

    const flashCard = (id: number) => {
        setIsFlashing({ ...isFlashing, [id]: true });
        setTimeout(() => {
            setIsFlashing({ ...isFlashing, [id]: false });
        }, 2000);
    };

    const copyToClipboard = async (value: string) => {
        if (value !== "") {
            try {
                await navigator.clipboard.writeText(value);
            } catch (e) {
                setError("En feil oppstod under kopiering til utklippstavlen.");
            }
        }
    };

    const handleCreateAuthProfile = async (): Promise<void> => {
        if (isCreating) {
            return;
        }

        try {
            setErrors({});
            setError("");
            setAuthProfileId(null);

            const organizationId = (organization?.id as number) || null;

            const body = {
                organizationId,
                name: "",
                email: "",
            };

            if (organization === null) {
                body.name = name;
                body.email = email;

                schema.parse(body);
            }
            setIsCreating(true);
            const profile = await createAuthProfile(body);
            queryClient.setQueryData<AuthProfile[]>(
                ["auth_profiles", slug],
                () => [profile, ...(authProfiles || [])],
            );

            flashCard(profile.id);
        } catch (e) {
            if (organization === null) {
                setErrors(processErrors(e as ZodError));
            } else {
                setError(
                    "En feil oppstod ved opprettelse av nye API-nøkler. Vennligst prøv igjen.",
                );
            }
        } finally {
            setIsCreating(false);
        }
    };

    const handleClientSecretRegeneration = async (
        authProfile: AuthProfile,
    ): Promise<void> => {
        if (isRegeneratingSecret[authProfile.id]) {
            return;
        }

        try {
            setError("");
            setIsRegeneratingSecret({
                ...isRegeneratingSecret,
                [authProfile.id]: true,
            });
            const newAuthProfile = await regenerateSecret(authProfile.id);
            queryClient.setQueryData<AuthProfile[]>(
                ["auth_profiles", slug],
                () =>
                    (authProfiles || []).map((profile) =>
                        profile.id === authProfile.id
                            ? {
                                  ...profile,
                                  clientSecret: newAuthProfile.clientSecret,
                              }
                            : profile,
                    ),
            );

            flashCard(authProfile.id);
        } catch (e) {
            setError(
                "En feil oppstod ved regenerering av client secret. Vennligst prøv igjen.",
            );
        } finally {
            setIsRegeneratingSecret({
                ...isRegeneratingSecret,
                [authProfile.id]: false,
            });
        }
    };

    const handleUpdateAuthProfile = async (authProfile: AuthProfile) => {
        if (isUpdating[authProfile.id]) {
            return;
        }

        try {
            setAuthProfileId(null);
            setErrors({});
            setError("");

            if (organization === null) {
                schema.parse(authProfile);
            }
            setIsUpdating({
                ...isUpdating,
                [authProfile.id]: true,
            });

            await updateAuthProfile(authProfile.id, {
                name: authProfile.name,
                email: authProfile.email,
            });
        } catch (e) {
            setAuthProfileId(authProfile.id);
            if (organization === null) {
                setErrors(processErrors(e as ZodError));
            } else {
                setError(
                    "En feil oppstod ved oppdatering. Vennligst prøv igjen.",
                );
            }
        } finally {
            setIsUpdating({
                ...isUpdating,
                [authProfile.id]: false,
            });
        }
    };

    const handleDeleteAuthProfile = async (authProfile: AuthProfile) => {
        if (isDeletingAuthProfile[authProfile.id]) {
            return;
        }

        try {
            setError("");

            setIsDeletingAuthProfile({
                ...isDeletingAuthProfile,
                [authProfile.id]: true,
            });

            setHasOpenedDeleteModal({
                ...hasOpenedDeleteModal,
                [authProfile.id]: false,
            });

            await deleteAuthProfile(authProfile.id);

            queryClient.setQueryData<AuthProfile[]>(
                ["auth_profiles", slug],
                () =>
                    (authProfiles || []).filter(
                        (profile) => profile.id !== authProfile.id,
                    ),
            );
        } catch (e) {
            setError(
                "En feil oppstod ved regenerering av client secret. Vennligst prøv igjen.",
            );
        } finally {
            setIsDeletingAuthProfile({
                ...isDeletingAuthProfile,
                [authProfile.id]: false,
            });
        }
    };

    if (isLoadingAuthenticatedUser) {
        return null;
    }

    return (
        <>
            <div className="flex justify-content-space-between align-center mt-6">
                {organization === null ? (
                    <h1 className="fs-h3 fw-500">Eksterne konsumenter</h1>
                ) : (
                    <div className="flex align-center">
                        <h1 className="fs-h3 fw-500">{organization?.name}</h1>
                        <Link
                            to={`/leverandorer/${organization?.slug}/rediger`}
                            className="btn btn-admin btn-sm ml-3"
                        >
                            <i className="fa-regular fa-pen-to-square mb-0"></i>
                            <span>Rediger</span>
                        </Link>
                    </div>
                )}

                {organization !== null && (
                    <Button
                        className="btn-admin btn-sm"
                        onClick={() => void handleCreateAuthProfile()}
                        disabled={isCreating}
                    >
                        {isCreating ? (
                            <>
                                <span className="spinner spinner-sm mr-3" />
                                Oppretter ny API-nøkkel...
                            </>
                        ) : (
                            "Opprett ny API-nøkkel"
                        )}
                    </Button>
                )}
            </div>

            <Alert
                message={
                    organization
                        ? "Husk å kopiere ut client secret ved opprettelse av API-nøkler, da denne ikke vil være mulig å hente ut igjen etter du har forlatt denne siden. Mister du den må nye nøkler genereres."
                        : "Eksterne konsumenter er et sett med API-nøkler som kan deles ut til selskaper. For å få tilgang til APIet benyttes samme endepunkter som ved direkte integrasjon."
                }
                type="info"
                mt={6}
            />

            {organization === null && (
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        void handleCreateAuthProfile();
                    }}
                >
                    <div className="mt-6">
                        <FormGroup
                            id="name"
                            className={classNames({
                                "is-invalid":
                                    authProfileId === null && errors.name,
                            })}
                            type="text"
                            label="Navn / beskrivelse knyttet til elementet"
                            placeholder="Navn / beskrivelse knyttet til elementet"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                        {authProfileId === null && (
                            <FieldError error={errors.name} />
                        )}
                    </div>
                    <div className="mt-5">
                        <FormGroup
                            id="email"
                            className={classNames({
                                "is-invalid":
                                    authProfileId === null && errors.email,
                            })}
                            type="text"
                            label="E-postadresse"
                            placeholder="E-postadresse"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        {authProfileId === null && (
                            <FieldError error={errors.email} />
                        )}
                    </div>
                    <div className="flex justify-content-end mt-6">
                        <Button
                            className="btn-admin btn-sm"
                            type="primary"
                            submit={true}
                            disabled={isCreating}
                        >
                            {isCreating ? (
                                <>
                                    <span className="spinner spinner-sm mr-3" />
                                    Oppretter ny ekstern konsument...
                                </>
                            ) : (
                                "Opprett ny ekstern konsument"
                            )}
                        </Button>
                    </div>
                </form>
            )}

            <div className="mt-7">
                {error && (
                    <>
                        <Alert message={error} type="danger" mt={8} />
                        <div className="mb-8"></div>
                    </>
                )}

                {isLoadingAuthProfiles ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {(authProfiles || []).length === 0 && (
                            <Alert
                                type="info"
                                message={
                                    organization === null
                                        ? "Fant ingen genererte eksterne konsumenter."
                                        : isAdmin(authenticatedUser)
                                        ? "Fant ingen genererte API-nøkler tilknyttet denne leverandøren."
                                        : "Fant ingen genererte API-nøkler."
                                }
                            />
                        )}
                        {(authProfiles || []).map((authProfile, idx) => (
                            <div
                                className={classNames("api-card", {
                                    "flash-cyan": isFlashing[authProfile.id],
                                })}
                                key={idx}
                            >
                                <div className="api-details">
                                    <span className="fw-500 block mb-6">
                                        Opprettet{" "}
                                        {authProfile.createdAt
                                            ? format(
                                                  new Date(
                                                      authProfile.createdAt,
                                                  ),
                                                  "d. LLL yyyy 'kl.' HH:mm",
                                                  {
                                                      locale: nb,
                                                  },
                                              )
                                            : "N/A"}
                                    </span>
                                    {!organization && (
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                void handleUpdateAuthProfile(
                                                    authProfile,
                                                );
                                            }}
                                        >
                                            <FormGroup
                                                id="email"
                                                className={classNames({
                                                    "is-invalid":
                                                        authProfileId ===
                                                            authProfile.id &&
                                                        errors.name,
                                                })}
                                                type="text"
                                                placeholder="Navn / beskrivelse knyttet til elementet"
                                                value={authProfile.name}
                                                onChange={(e) => {
                                                    queryClient.setQueryData<
                                                        AuthProfile[]
                                                    >(
                                                        ["auth_profiles", slug],
                                                        () =>
                                                            (
                                                                authProfiles ||
                                                                []
                                                            ).map((profile) =>
                                                                profile.id ===
                                                                authProfile.id
                                                                    ? {
                                                                          ...profile,
                                                                          name: e
                                                                              .target
                                                                              .value,
                                                                      }
                                                                    : profile,
                                                            ),
                                                    );
                                                }}
                                            />
                                            {authProfile.id ===
                                                authProfileId && (
                                                <FieldError
                                                    error={errors.name}
                                                />
                                            )}

                                            <FormGroup
                                                id="email"
                                                className={classNames("mt-5", {
                                                    "is-invalid":
                                                        authProfileId ===
                                                            authProfile.id &&
                                                        errors.email,
                                                })}
                                                type="text"
                                                placeholder="E-postadresse"
                                                value={authProfile.email}
                                                onChange={(e) => {
                                                    queryClient.setQueryData<
                                                        AuthProfile[]
                                                    >(
                                                        ["auth_profiles", slug],
                                                        () =>
                                                            (
                                                                authProfiles ||
                                                                []
                                                            ).map((profile) =>
                                                                profile.id ===
                                                                authProfile.id
                                                                    ? {
                                                                          ...profile,
                                                                          email: e
                                                                              .target
                                                                              .value,
                                                                      }
                                                                    : profile,
                                                            ),
                                                    );
                                                }}
                                            />
                                            {authProfile.id ===
                                                authProfileId && (
                                                <FieldError
                                                    error={errors.email}
                                                />
                                            )}

                                            <Button
                                                className="btn-admin btn-sm mt-5 mb-4"
                                                type="primary"
                                                submit={true}
                                                disabled={
                                                    isUpdating[authProfile.id]
                                                }
                                            >
                                                Oppdater
                                            </Button>
                                        </form>
                                    )}
                                    <div className="flex gap-4 align-center mt-6">
                                        <span>Client ID:</span>
                                        <span className="flex">
                                            <button
                                                className="api-copy"
                                                onClick={() =>
                                                    void copyToClipboard(
                                                        authProfile.clientId,
                                                    )
                                                }
                                            >
                                                <i className="fa-regular fa-copy" />
                                            </button>
                                            {authProfile.clientId}
                                        </span>
                                    </div>
                                    <div className="flex gap-4 align-center mt-3">
                                        <span>Client secret:</span>
                                        <span className="flex">
                                            {authProfile.clientSecret && (
                                                <button
                                                    className="api-copy"
                                                    onClick={() =>
                                                        void copyToClipboard(
                                                            authProfile.clientSecret as string,
                                                        )
                                                    }
                                                >
                                                    <i className="fa-regular fa-copy" />
                                                </button>
                                            )}

                                            <span className="client-secret">
                                                {authProfile.clientSecret ||
                                                    "**********"}
                                            </span>
                                        </span>
                                    </div>

                                    <Button
                                        type="secondary"
                                        className="btn-admin btn-sm mt-4"
                                        onClick={() =>
                                            void handleClientSecretRegeneration(
                                                authProfile,
                                            )
                                        }
                                        disabled={
                                            isRegeneratingSecret[authProfile.id]
                                        }
                                    >
                                        {isRegeneratingSecret[
                                            authProfile.id
                                        ] ? (
                                            <>
                                                <span className="spinner spinner-sm" />
                                                <span className="ml-3">
                                                    Regenererer client secret...
                                                </span>
                                            </>
                                        ) : (
                                            "Regenerer client secret"
                                        )}
                                    </Button>
                                </div>
                                <div className="flex align-center justify-content-space-between">
                                    <Button
                                        className="btn-admin btn-sm btn-danger"
                                        type="secondary"
                                        onClick={() =>
                                            setHasOpenedDeleteModal({
                                                ...hasOpenedDeleteModal,
                                                [authProfile.id]: true,
                                            })
                                        }
                                        disabled={
                                            isDeletingAuthProfile[
                                                authProfile.id
                                            ]
                                        }
                                    >
                                        {isDeletingAuthProfile[
                                            authProfile.id
                                        ] ? (
                                            <>
                                                <span className="spinner spinner-sm" />
                                                <span className="ml-3">
                                                    Sletter API-nøkkel...
                                                </span>
                                            </>
                                        ) : (
                                            "Slett API-nøkkel"
                                        )}
                                    </Button>
                                </div>
                                <ConfirmationModal
                                    label={
                                        <p>
                                            Er du sikker på at du ønsker å
                                            slette denne API-nøkkelen?
                                        </p>
                                    }
                                    isOpen={
                                        hasOpenedDeleteModal[authProfile.id]
                                    }
                                    confirmBtnType="danger"
                                    onConfirm={() =>
                                        void handleDeleteAuthProfile(
                                            authProfile,
                                        )
                                    }
                                    onClose={() =>
                                        setHasOpenedDeleteModal({
                                            ...hasOpenedDeleteModal,
                                            [authProfile.id]: false,
                                        })
                                    }
                                />
                            </div>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};
export default AuthProfiles;
