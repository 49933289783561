import React from "react";
import classNames from "classnames";
import { Product, ProductVersion } from "../types";
import { coloredStatus, status, statusIcon } from "../util/versionTools";

import "./ProductStatus.scss";

const ProductStatus: React.FC<{
    product: Product;
    version: ProductVersion;
    pill?: boolean;
}> = ({ product, version, pill = false }) => {
    if (pill) {
        return (
            <span
                className={classNames(
                    "pill pill-admin pill-success",
                    `${coloredStatus(product, version)}-bordered`,
                )}
            >
                {status(product, version)}
            </span>
        );
    }

    return (
        <div className="flex align-items-center item-status">
            <i
                className={classNames(
                    "fa-regular",
                    statusIcon(product, version),
                )}
            />
            <span>{status(product, version)}</span>
        </div>
    );
};
export default ProductStatus;
