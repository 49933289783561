import { useQuery } from "react-query";
import axios from "../util/axios";
import { AxiosResponse } from "axios";
import type { County } from "../types";

export default function useCounties() {
    const retrieveCounties = async (): Promise<County[]> => {
        const response: AxiosResponse<County[]> = await axios.get("counties");

        return response.data;
    };

    return useQuery({
        queryFn: retrieveCounties,
        queryKey: ["counties"],
        retry: 3,
    });
}
