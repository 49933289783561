enum PeriodUnit {
    Day,
    Month,
    Year,
}

const periodUnitMap: { [key in PeriodUnit]: "day" | "month" | "year" } = {
    [PeriodUnit.Day]: "day",
    [PeriodUnit.Month]: "month",
    [PeriodUnit.Year]: "year",
};

/**
 * convers a period to months
 * @param number the piriod
 * @param unit the PeriodUnit the piriod is in
 */
const periodToMonths = (
    number: number | null | undefined,
    unit: PeriodUnit | null | undefined,
) =>
    number !== undefined && number !== null
        ? unit === PeriodUnit.Day
            ? number / 30
            : unit === PeriodUnit.Month
            ? number
            : unit === PeriodUnit.Year
            ? number * 12
            : NaN
        : NaN;

const periodSuffix = (period: number | string, periodUnit: PeriodUnit) => {
    const many = Number(period) !== 1;

    if (many) {
        return periodUnit === PeriodUnit.Day
            ? "dager"
            : periodUnit === PeriodUnit.Month
            ? "måneder"
            : "år";
    }

    return periodUnit === PeriodUnit.Day
        ? "dag"
        : periodUnit === PeriodUnit.Month
        ? "måned"
        : "år";
};

const periodUnitFlippedMap: { [key: string]: PeriodUnit } = Object.entries(
    periodUnitMap,
).reduce(
    (prev, curr) => ({
        ...prev,
        [curr[1]]: Number(curr[0]),
    }),
    {},
);

type PeriodUnitValues = (typeof periodUnitMap)[keyof typeof periodUnitMap];

const toEnumValue = (value: string | null): PeriodUnit | null =>
    value !== null ? periodUnitFlippedMap[value] : null;

const toHumanValue = (
    value: PeriodUnit | null,
): (typeof periodUnitMap)[keyof typeof periodUnitMap] | null =>
    value !== null ? periodUnitMap[value] : null;

export default PeriodUnit;
export {
    toEnumValue,
    toHumanValue,
    periodToMonths,
    periodSuffix,
    type PeriodUnitValues,
};
