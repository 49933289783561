import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { isFuture } from "date-fns";
import axios from "../util/axios";
import type { Organization } from "../types";
import { useStore } from ".";

export default function useOrganizations() {
    const { authToken } = useStore();
    const retrieveOrganization = async (): Promise<Organization[]> => {
        const response: AxiosResponse<Organization[]> =
            await axios.get("organizations");

        return response.data;
    };
    return useQuery("organizations", async () => await retrieveOrganization(), {
        retry: 3,
        enabled: authToken !== null && isFuture(new Date(authToken.expiresAt)),
    });
}
