enum PublishingStatus {
    Approved,
    Published,
    Rejected,
    Draft,
    Pending,
    Deactivated,
}

const publishingStatusMap: { [key in PublishingStatus]: string } = {
    [PublishingStatus.Approved]: "Godkjent",
    [PublishingStatus.Published]: "Publisert",
    [PublishingStatus.Rejected]: "Avvist",
    [PublishingStatus.Draft]: "Utkast",
    [PublishingStatus.Pending]: "Avventer godkjennelse",
    [PublishingStatus.Deactivated]: "Deaktivert",
};

type PublishingStatusValues =
    (typeof publishingStatusMap)[keyof typeof publishingStatusMap];

const publishingStatusFlippedMap: { [key: string]: PublishingStatus } =
    Object.entries(publishingStatusMap).reduce(
        (prev, curr) => ({
            ...prev,
            [curr[1]]: Number(curr[0]),
        }),
        {},
    );

const validOptions: PublishingStatus[] = [
    PublishingStatus.Published,
    PublishingStatus.Rejected,
    PublishingStatus.Draft,
    PublishingStatus.Pending,
];

const isValidOption = (publishingStatus: PublishingStatus) =>
    validOptions.includes(publishingStatus);

const toEnumValue = (value: string): PublishingStatus =>
    publishingStatusFlippedMap[value];

const toHumanValue = (value: PublishingStatus): string =>
    publishingStatusMap[value];

const DbPublishingStatusMap: {
    [key in PublishingStatus]:
        | "approved"
        | "published"
        | "rejected"
        | "draft"
        | "pending"
        | "deactivated";
} = {
    [PublishingStatus.Approved]: "approved",
    [PublishingStatus.Published]: "published",
    [PublishingStatus.Rejected]: "rejected",
    [PublishingStatus.Draft]: "draft",
    [PublishingStatus.Pending]: "pending",
    [PublishingStatus.Deactivated]: "deactivated",
};

type DbPublishingStatusValues =
    (typeof DbPublishingStatusMap)[keyof typeof DbPublishingStatusMap];

const DbPublishingStatusFlipedMap: { [key: string]: PublishingStatus } =
    Object.entries(DbPublishingStatusMap).reduce(
        (prev, curr) => ({
            ...prev,
            [curr[1]]: Number(curr[0]),
        }),
        {},
    );

const dbToEnumValue = (value: DbPublishingStatusValues): PublishingStatus =>
    DbPublishingStatusFlipedMap[value];

const dbToHumanValue = (value: PublishingStatus): DbPublishingStatusValues =>
    DbPublishingStatusMap[value];

export {
    type DbPublishingStatusValues,
    dbToEnumValue,
    dbToHumanValue,
    DbPublishingStatusMap,
    toEnumValue,
    toHumanValue,
    validOptions,
    isValidOption,
    type PublishingStatusValues,
};

export default PublishingStatus;
