import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";

const Pages = ({
    page,
    pages,
    range = 3,
    setPage,
}: {
    page: number;
    pages: number;
    range?: number;
    setPage: (p: number) => void;
}) => {
    const navigate = useNavigate();
    const [query] = useSearchParams();

    const isCurrentPage = (p: number) =>
        page === p || (p === 1 && page === null);

    const rangeIndexStart =
        (page - 1 >= 1 ? page - 1 : page) - Math.floor((range - 1) / 2);
    const calculatedRange = _.range(1, pages + 1).slice(
        rangeIndexStart,
        rangeIndexStart + range,
    );

    if (pages <= 1) {
        return null;
    }

    const goToPage = (page: number) => {
        const path = [];

        for (const entry of query.entries() as unknown as [string, string][]) {
            if (entry[0] === "page") {
                continue;
            }

            path.push(`${entry[0]}=${entry[1]}`);
        }

        path.push(`page=${page}`);
        setPage(page);
        navigate(`?${path.join("&")}`);
    };

    const handlePageChange = (p: number) => {
        if (p < 1) {
            goToPage(1);
        } else if (p > pages) {
            goToPage(pages);
            p = pages;
        } else {
            goToPage(p);
        }
    };

    return (
        <div className="pagination mt-8">
            <button
                className={`pagination-item pagination-btn pagination-btn-prev${
                    page <= 1 ? " disabled" : ""
                }`}
                onClick={() => handlePageChange(page - 1)}
                disabled={page <= 1}
                aria-label="Forrige side"
            >
                <i className="fa-regular fa-chevron-left"></i>
            </button>
            {page - range >= 1 && (
                <button
                    key={1}
                    className="pagination-item pagination-btn"
                    onClick={() => handlePageChange(1)}
                >
                    1
                </button>
            )}
            {page - range >= 1 && (
                <button
                    key="dots-first"
                    className="pagination-item pagination-ellipsis"
                >
                    ...
                </button>
            )}
            {calculatedRange.map((p) => (
                <button
                    key={p}
                    className={`pagination-item pagination-btn ${
                        isCurrentPage(p) ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(p)}
                >
                    {p}
                </button>
            ))}
            {calculatedRange[calculatedRange.length - range] <
                pages - range && (
                <button
                    key="dots-last"
                    className="pagination-item pagination-ellipsis"
                >
                    ...
                </button>
            )}
            {calculatedRange[calculatedRange.length - 1] < pages && (
                <button
                    key={pages}
                    className="pagination-item pagination-btn"
                    onClick={() => handlePageChange(pages)}
                >
                    {pages}
                </button>
            )}
            <button
                className={`pagination-item pagination-btn pagination-btn-next${
                    page >= pages ? " disabled" : ""
                }`}
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= pages}
                aria-label="Neste side"
            >
                <i className="fa-regular fa-chevron-right"></i>
            </button>
        </div>
    );
};

export default Pages;
