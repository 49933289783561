import { ZodError, ZodIssueCode } from "zod";
export interface Error {
    [key: string]: string;
}

export const processErrors = (error: ZodError): Error => {
    return error.errors.reduce((curr, err) => {
        if (err.code === ZodIssueCode.invalid_union) {
            const unionErrors = err.unionErrors.map((unionError) =>
                processErrors(unionError),
            );
            return {
                ...curr,
                ...unionErrors,
            };
        }

        return {
            ...curr,
            [err.path.join(".")]: err.message,
        };
    }, {});
};
