import React from "react";
import classNames from "classnames";
import "./index.scss";

const Search = ({
    icon = "",
    placeholder = "Søk",
    value,
    className = "",
    onChange,
}: {
    icon?: string;
    placeholder?: string;
    value: string;
    className?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <div className={classNames("search-container mt-4", className)}>
            {icon && <i className={`fa-regular ${icon}`} />}
            <input
                className="small"
                placeholder={placeholder}
                type="search"
                value={value}
                onChange={onChange}
            />
        </div>
    );
};

export default Search;
