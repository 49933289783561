import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { isFuture } from "date-fns";
import axios from "../util/axios";
import type { LegalEntity } from "../types";
import { useStore } from ".";

export default function useLegalEntities(
    onlyBankruptOrInLiquidation: boolean = false,
    showExcluded: boolean = false,
) {
    const { authToken } = useStore();
    const retrieveLegalEntities = async (
        onlyBankruptOrInLiquidation: boolean,
        showExcluded: boolean,
    ): Promise<LegalEntity[]> => {
        const response: AxiosResponse<LegalEntity[]> = await axios.get(
            "legal_entities",
            {
                params: {
                    onlyBankruptOrInLiquidation: onlyBankruptOrInLiquidation
                        ? "true"
                        : "false",
                    showExcluded: showExcluded ? "true" : "false",
                },
            },
        );

        return response.data;
    };

    return useQuery(
        ["legalEntities", onlyBankruptOrInLiquidation, showExcluded],
        async () =>
            await retrieveLegalEntities(
                onlyBankruptOrInLiquidation,
                showExcluded,
            ),
        {
            retry: 3,
            enabled:
                authToken !== null && isFuture(new Date(authToken.expiresAt)),
        },
    );
}
