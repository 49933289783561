import classNames from "classnames";
import sortingOrderArrows from "../assets/images/sort_button.svg";
import sortingOrderArrowsNone from "../assets/images/sort_button_none.svg";

import "./SortButton.scss";

interface ISortButton {
    clicked: "asc" | "desc" | false;
    onClick: () => void;
    name: string;
}

const SortButton = ({ clicked, onClick, name }: ISortButton) => {
    return (
        <button
            className={classNames(
                "flex align-center sorting-order-arrows background-inherit",
                {
                    clicked: clicked === "desc",
                },
            )}
            onClick={() => {
                onClick();
            }}
        >
            <span>{name}</span>{" "}
            <img
                className="ml-3"
                src={
                    clicked === false
                        ? sortingOrderArrowsNone
                        : sortingOrderArrows
                }
                alt="Sorter"
            />
        </button>
    );
};

export default SortButton;
