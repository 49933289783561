import { FocusEvent, useState, useRef } from "react";
import { useQueryClient } from "react-query";
import classNames from "classnames";
import logo from "../../assets/images/forbrukerradet_logo_symbol.svg";
import { useStore, useUser } from "../../hooks";
import { isAdmin, isGuest, getInitials } from "../../util/user";
import "./index.scss";
const Header = () => {
    const [accountModalExpanded, setAccountModalExpanded] =
        useState<boolean>(false);
    const {
        authToken,
        menuAnimating,
        menuExpanded,
        setAuthToken,
        setMenuAnimating,
        setMenuExpanded,
        setUser,
    } = useStore();
    const { data: user } = useUser();
    const queryClient = useQueryClient();
    const accountModalRef = useRef<HTMLDivElement>({} as HTMLDivElement);

    const handleExpansionOfAccountModal = (value: boolean = true) => {
        if (
            (!accountModalExpanded && value) ||
            (accountModalExpanded && !value)
        ) {
            setAccountModalExpanded(value);
        }
    };

    const handleBlurOfAccountModal = (e: FocusEvent<HTMLDivElement>) => {
        if (
            accountModalExpanded &&
            !accountModalRef.current?.contains(e.relatedTarget)
        ) {
            setAccountModalExpanded(false);
        }
    };

    const handleLogout = async () => {
        await queryClient.invalidateQueries(["user"]);
        setAuthToken("", null);
        setUser(null);
        setAccountModalExpanded(false);
    };

    return (
        <header className="admin-header">
            {!isGuest(user) && authToken !== null && (
                <button
                    className="menu-button"
                    aria-label={menuExpanded ? "Lukk meny" : "Åpne meny"}
                    onClick={() => {
                        if (!menuExpanded) {
                            setMenuExpanded(true);
                        } else {
                            setMenuAnimating();
                        }
                    }}
                >
                    <i
                        className={classNames("fbr-icon-bars", {
                            "is-active": !menuAnimating && menuExpanded,
                        })}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </i>
                </button>
            )}
            <a href="./">
                <div className="logo">
                    <img
                        src={logo}
                        alt="Forbrukerrådet logo"
                        className="header-logo"
                    />
                </div>
            </a>
            {!isGuest(user) && authToken !== null && (
                <nav
                    className="header-admin-account"
                    onBlur={handleBlurOfAccountModal}
                >
                    <button
                        onClick={() => {
                            handleExpansionOfAccountModal(
                                !accountModalExpanded,
                            );
                        }}
                        className="user"
                    >
                        <span>{getInitials(user)}</span>
                    </button>

                    {accountModalExpanded && (
                        <div
                            className="account-modal color-tile bg-white fs-small"
                            onFocus={() => handleExpansionOfAccountModal()}
                            ref={accountModalRef}
                        >
                            <div className="account-info p-4">
                                <p>
                                    {user?.name}
                                    {isAdmin(user) && " (administrator)"}
                                </p>
                                <p className="text-link mt-2">
                                    <a
                                        href={`mailto:${user?.email}`}
                                        className="text-link"
                                    >
                                        {user?.email}
                                    </a>
                                </p>
                            </div>
                            <button
                                className="account-action px-4 py-2"
                                onClick={() => void handleLogout()}
                            >
                                <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                <span>Logg ut</span>
                            </button>
                        </div>
                    )}
                </nav>
            )}
        </header>
    );
};

export default Header;
