import React, { ChangeEvent, MouseEvent, ReactNode } from "react";
import { DynamicField, FieldError } from "./";
import SearchSelect, {
    GroupedSearchSelectOption,
    SearchSelectOption,
} from "./SearchSelect";
import classNames from "classnames";

import "./MultipleSelect.scss";

interface MultipleSelectOpt {
    value: null | string | number;
    label: string;
}
interface MultipleSelectOption {
    options: MultipleSelectOpt[];
    group?: null | string;
}

interface MultipleSelectValue {
    [key: string]: {
        val: number | string;
        error?: boolean;
        errorMsg?: string;
    };
}
type InputType = "multipleSelect" | "text" | "number";

const MultipleSelect = ({
    id,
    header,
    addOption,
    removeOption,
    className,
    addText = "Legg til",
    values,
    selectors,
    search = true,
    onCreateOption = undefined,
    placeholder,
}: {
    id?: string;
    header: ReactNode;
    addOption: (e: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    removeOption: (idx: string | number) => void;
    addText?: string;
    values: MultipleSelectValue[];
    search?: boolean;
    onCreateOption?: (idx: number, val: string) => void;
    placeholder: string;
    selectors: {
        [key: string]: {
            value: string;
            label: string;
            options?: MultipleSelectOption[];
            required?: boolean;
            onChange: (
                idx: number,
                e: ChangeEvent<unknown> | SearchSelectOption,
            ) => void;
            inputType?: InputType;
            prefix?: string;
            unit?: "kr" | "ore";
            error?: boolean;
            errorMsg?: string;
        };
    };
}) => {
    return (
        <div id={id} className={`${className} card-w-header rounded-md`}>
            <div className="card-header align-center mb-0">
                <h3 className="card-title fs-body fw-500">{header}</h3>
                <div className="card-options">
                    <button
                        type="button"
                        className="card-options-add btn btn-admin btn-sm"
                        onClick={addOption}
                    >
                        <i className="fa fa-solid fa-plus"></i>
                        {addText}
                    </button>
                </div>
            </div>
            <div className="card-body">
                <div className="flex">
                    {!!values.length &&
                        Object.keys(selectors).map((key) => {
                            const selector = selectors[key];
                            return (
                                <label htmlFor="form-category">
                                    {selector.label}{" "}
                                    {selector.required == false || (
                                        <span className="required">*</span>
                                    )}
                                </label>
                            );
                        })}
                </div>
                <div className="form-content-wrap">
                    {values.map((value, idx) => {
                        return (
                            <div
                                className={classNames({
                                    "mt-5": idx !== 0,
                                })}
                                key={idx}
                            >
                                <div className="form-item flex">
                                    <div className="form-item-content flex gap-5">
                                        {Object.keys(selectors).map((key) => {
                                            const selector = selectors[key];
                                            const inputType = selector.inputType
                                                ? selector.inputType
                                                : "multipleSelect";

                                            return (
                                                <div>
                                                    {(inputType ===
                                                        "multipleSelect" && (
                                                        <MultipleSelectComp
                                                            selector={selector}
                                                            val={value}
                                                            idx={idx}
                                                            search={search}
                                                            placeholder={
                                                                placeholder
                                                            }
                                                            onCreateOption={
                                                                onCreateOption
                                                            }
                                                        />
                                                    )) ||
                                                        (inputType ===
                                                            "text" && (
                                                            <TextComp
                                                                selector={
                                                                    selector
                                                                }
                                                                val={value}
                                                                idx={idx}
                                                            />
                                                        )) ||
                                                        (inputType ===
                                                            "number" && (
                                                            <NumberComp
                                                                selector={
                                                                    selector
                                                                }
                                                                val={value}
                                                                idx={idx}
                                                                prefix={
                                                                    selector.prefix
                                                                }
                                                                unit={
                                                                    selector.unit
                                                                }
                                                            />
                                                        ))}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <button
                                        className="card-options-remove"
                                        onClick={() => {
                                            removeOption(idx);
                                        }}
                                    >
                                        <i className="fa fa-solid fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const MultipleSelectComp: React.FC<{
    selector: {
        label: string;
        options?: MultipleSelectOption[];
        required?: boolean;
        onChange: (
            idx: number,
            e: ChangeEvent<HTMLSelectElement> | SearchSelectOption,
        ) => void;
        inputType?: InputType;
    };
    placeholder: string;
    search: boolean;
    onCreateOption?: (idx: number, val: string) => void;
    val: MultipleSelectValue;
    idx: number;
}> = ({ selector, placeholder, val, idx, search, onCreateOption }) => {
    const errorMsg = val[selector.label]?.errorMsg || "Velg et alternativ";
    return (
        <>
            <div className="form-select">
                <SearchSelect
                    key={idx}
                    placeholder={placeholder}
                    value={selector.options
                        ?.reduce(
                            (
                                prev: SearchSelectOption[],
                                curr: MultipleSelectOption,
                            ) => {
                                return [...prev, ...curr.options];
                            },
                            [],
                        )
                        ?.find((opt) =>
                            opt.value !== null
                                ? opt.value === val[selector.label].val
                                : opt.label === val[selector.label].val,
                        )}
                    isSearchable={search}
                    onChange={(val) =>
                        selector.onChange(idx, val as SearchSelectOption)
                    }
                    options={
                        selector.options?.reduce(
                            (
                                prev: (
                                    | GroupedSearchSelectOption
                                    | SearchSelectOption
                                )[],
                                curr: MultipleSelectOption,
                            ) => {
                                if (curr.group) {
                                    return [
                                        ...prev,
                                        {
                                            label: curr.group,
                                            options: curr.options,
                                        },
                                    ];
                                } else {
                                    return [...prev, ...curr.options];
                                }
                            },
                            [],
                        ) || []
                    }
                    onCreateOption={
                        onCreateOption !== undefined
                            ? (val: string) => onCreateOption(idx, val)
                            : undefined
                    }
                />
            </div>
            <FieldError
                error={val[selector.label].error ? errorMsg : undefined}
            />
        </>
    );
};

const TextComp: React.FC<{
    selector: {
        label: string;
        required?: boolean;
        onChange: (idx: number, e: ChangeEvent<HTMLInputElement>) => void;
        inputType?: InputType;
    };
    val: MultipleSelectValue;
    idx: number;
}> = ({ selector, val, idx }) => {
    const errorMsg =
        val[selector.label].errorMsg || "Du må fylle ut tekstfeltet.";
    return (
        <>
            <div className="form-select form-text">
                <input
                    className={classNames({
                        "is-invalid": val[selector.label].error,
                    })}
                    id="form-category"
                    value={String(val[selector.label].val) || ""}
                    onChange={(e) => selector.onChange(idx, e)}
                    type="text"
                />
                <FieldError
                    error={val[selector.label].error ? errorMsg : undefined}
                />
            </div>
        </>
    );
};

const NumberComp: React.FC<{
    selector: {
        label: string;
        required?: boolean;
        onChange: (idx: number, e: ChangeEvent<HTMLInputElement>) => void;
        inputType?: InputType;
    };
    val: MultipleSelectValue;
    idx: number;
    prefix?: string;
    unit?: "kr" | "ore";
}> = ({ selector, val, idx, prefix, unit }) => {
    const errorMsg = val[selector.label].errorMsg || "Fyll ut feltet.";

    return (
        <>
            <div className="form-select form-text">
                {prefix ? (
                    <>
                        <DynamicField
                            className={classNames("dynamicfield input-field", {
                                "is-invalid": val[selector.label].error,
                            })}
                            value={String(val[selector.label].val || "")}
                            onChange={(e) => selector.onChange(idx, e)}
                            unit={unit}
                            helper={prefix}
                            format="({value})"
                        />
                        <FieldError
                            error={
                                val[selector.label].error ? errorMsg : undefined
                            }
                        />
                    </>
                ) : (
                    <>
                        <input
                            className={classNames({
                                "is-invalid": val[selector.label].error,
                            })}
                            id="form-category"
                            value={String(val[selector.label]) || "20"}
                            onChange={(e) => selector.onChange(idx, e)}
                            type="number"
                        />
                        <FieldError
                            error={
                                val[selector.label].error ? errorMsg : undefined
                            }
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default MultipleSelect;
