import { useQuery } from "react-query";
import axios from "../util/axios";
import { AxiosResponse } from "axios";
import type { Municipality } from "../types";

export default function useMunicipalities() {
    const retrieveMunicipalities = async (): Promise<Municipality[]> => {
        const response: AxiosResponse<Municipality[]> =
            await axios.get("municipalities");

        return response.data;
    };

    return useQuery({
        queryFn: retrieveMunicipalities,
        queryKey: ["municipalities"],
        retry: 3,
    });
}
