import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { HelmetProvider, Helmet } from "react-helmet-async";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ReactQueryDevtools } from "react-query/devtools";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient();

// Build cdn url
const cdnHost: string | undefined = process.env.REACT_APP_CDN_HOST;
const cdnVersion: string | undefined = process.env.REACT_APP_CDN_VERSION;
const cdnUrl: string = `${cdnHost}${
    cdnVersion?.match(/^[0-9]+\.[0-9]+\.[0-9]+$/) ? "v" : ""
}${cdnVersion ? cdnVersion + "/" : ""}`;

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <HelmetProvider>
                    <Helmet>
                        {cdnHost && (
                            <>
                                <link
                                    rel="stylesheet"
                                    type="text/css"
                                    href={`${cdnUrl}vendor.css`}
                                />
                                <link
                                    rel="stylesheet"
                                    type="text/css"
                                    href={`${cdnUrl}main.css`}
                                />
                            </>
                        )}
                    </Helmet>
                    <App />
                </HelmetProvider>
                {/* reqires "process.env.NODE_ENV === 'development'" */}
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
