import "./index.scss";

const Footer = () => {
    return (
        <footer className="admin-footer">
            <span>
                Strømpris admin –{" "}
                <a href="mailto:strompris@forbrukerradet.no">
                    strompris@forbrukerradet.no
                </a>
            </span>
        </footer>
    );
};

export default Footer;
