import type { User } from "../types";

export const isAdmin = (user: User | undefined) =>
    user !== undefined && user.roles.some((role) => role.name === "admin");

export const isUser = (user: User | undefined) =>
    user !== undefined && !isAdmin(user);

export const isGuest = (user: User | undefined) => user === undefined;

export const getInitials = (user: User | undefined) => {
    if (user === undefined) {
        return "";
    }

    if (user.name) {
        const nameParts = user.name.split(" ");
        if (nameParts.length > 1) {
            return (
                nameParts[0].charAt(0) +
                nameParts[nameParts.length - 1].charAt(0)
            ).toLocaleUpperCase("nb");
        } else {
            return nameParts[0].slice(0, 2).toLocaleUpperCase("nb");
        }
    }

    const emailParts = user.email.split("@")[0].split(".");
    if (emailParts.length > 1) {
        return (
            emailParts[0].charAt(0) +
            emailParts[emailParts.length - 1].charAt(0)
        ).toLocaleUpperCase("nb");
    }

    return emailParts[0].slice(0, 2).toLocaleUpperCase("nb");
};
