import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { isFuture } from "date-fns";
import axios from "../util/axios";
import type { ProductChange } from "../types";
import { useStore } from ".";

interface ProductChangeReturn {
    data: ProductChange[];
    meta: {
        pages: number;
    };
}

export default function useProductChanges(
    search: string = "",
    page: number = 1,
    limit: number | null = null,
) {
    const { authToken } = useStore();
    const retrieveProductChanges = async (
        search: string,
        page: number,
        limit: number | null,
    ): Promise<ProductChangeReturn> => {
        const response: AxiosResponse<ProductChangeReturn> = await axios.get(
            `products/changes?page=${page}${
                limit !== null ? `&limit=${limit}` : ""
            }${search ? `&search=${encodeURIComponent(search)}` : ""}`,
        );

        return response.data;
    };

    return useQuery(
        ["product-changes", page, limit, search],
        async () => await retrieveProductChanges(search, page, limit),
        {
            retry: 3,
            enabled:
                authToken !== null && isFuture(new Date(authToken.expiresAt)),
        },
    );
}
