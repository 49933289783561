import { useQuery } from "react-query";
import useStore from "./useStore";
import axios from "../util/axios";
import { AxiosResponse } from "axios";
import type { Organization, User, UserRole } from "../types";

export default function useUser(id: number | null = null) {
    const { authToken, user, setUser } = useStore();
    const retrieveUser = async (id: number | null): Promise<User> => {
        if (id === null && user !== null) {
            return user;
        }

        const response: AxiosResponse<{
            id: number;
            name: string;
            email: string;
            phone: string | null;
            roles: UserRole[];
            organizations?: Organization[] | undefined;
        }> = await axios.get(`users/${id !== null ? id : "current"}`);

        return response.data;
    };

    return useQuery({
        queryKey: ["user", id],
        queryFn: async () => await retrieveUser(id),
        onSuccess: (user: User) => {
            if (id === null) {
                setUser(user);
            }
        },
        retry: 3,
        enabled: authToken !== null,
    });
}
