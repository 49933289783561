import React, { ReactNode } from "react";
import { Button } from "frno-react";
import classNames from "classnames";
import { useStore } from "../../hooks";
import { stopPropagation } from "../../util/react";
import ModalHandler from "./ModalHandler";
import style from "./Index.module.scss";

const Index = ({
    children,
    declineText = "Nei, avbryt",
    acceptText = "Ja, jeg er sikker",
    onDecline,
    onAccept,
}: {
    children: ReactNode;
    declineText?: string;
    acceptText?: string;
    onDecline?: () => void;
    onAccept?: () => void;
}) => {
    const { setCurrentModal, setModalEnabled } = useStore();
    return (
        <div className={classNames([style.Modal])} onClick={stopPropagation}>
            {children}
            <div className={classNames([style.buttonRow])}>
                <Button
                    className="btn-admin btn-sm"
                    onClick={() => {
                        setCurrentModal(null);
                        setModalEnabled(false);

                        if (onDecline) {
                            onDecline();
                        }
                    }}
                >
                    {declineText}
                </Button>
                <Button
                    className={classNames(["btn-admin btn-sm btn-danger"])}
                    onClick={() => {
                        setCurrentModal(null);
                        setModalEnabled(false);

                        if (onAccept) {
                            onAccept();
                        }
                    }}
                >
                    {acceptText}
                </Button>
            </div>
        </div>
    );
};

export default Index;

export { ModalHandler };
