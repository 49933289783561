enum Customer {
    Everyone,
    New,
    Existing,
    ExistingWithMembership,
    NewWithMembership,
    WithMembership,
    ExistingAndNew,
    ExistingAndNewWithMembership,
}

const customerMap: { [key in Customer]: string[] } = {
    [Customer.Everyone]: ["Alle"],
    [Customer.New]: ["Nye kunder"],
    [Customer.Existing]: ["Eksisterende kunder"],
    [Customer.ExistingWithMembership]: ["Eksisterende kunder", "Medlemmer"],
    [Customer.NewWithMembership]: ["Nye kunder", "Medlemmer"],
    [Customer.WithMembership]: ["Alle", "Medlemmer"],
    [Customer.ExistingAndNew]: ["Nye kunder", "Eksisterende kunder"],
    [Customer.ExistingAndNewWithMembership]: [
        "Nye kunder",
        "Eksisterende kunder",
        "Medlemmer",
    ],
};
type CustomerValues = (typeof customerMap)[keyof typeof customerMap];

const DbCustomerMap: {
    [key in Customer]:
        | "allCustomers"
        | "newCustomers"
        | "existingCustomers"
        | "existingAndMembers"
        | "newAndMembers"
        | "membersOnly"
        | "newAndExisting"
        | "newAndExistingAndMembers";
} = {
    [Customer.Everyone]: "allCustomers",
    [Customer.New]: "newCustomers",
    [Customer.Existing]: "existingCustomers",
    [Customer.ExistingWithMembership]: "existingAndMembers",
    [Customer.NewWithMembership]: "newAndMembers",
    [Customer.WithMembership]: "membersOnly",
    [Customer.ExistingAndNew]: "newAndExisting",
    [Customer.ExistingAndNewWithMembership]: "newAndExistingAndMembers",
};
type DbCustomerValues = (typeof DbCustomerMap)[keyof typeof DbCustomerMap];

const DbCustomerFlippedMap: { [key: string]: Customer } = Object.entries(
    DbCustomerMap,
).reduce(
    (prev, curr) => ({
        ...prev,
        [curr[1]]: Number(curr[0]),
    }),
    {},
);

const toEnumValue = (value: DbCustomerValues | null): Customer | null =>
    value !== null ? DbCustomerFlippedMap[value] : null;

const DbtoHumanValue = (value: Customer | null): DbCustomerValues | null =>
    value !== null ? DbCustomerMap[value] : null;

const toHumanValue = (
    value: Customer | null,
    hideMembership: boolean = false,
): CustomerValues | null => {
    if (hideMembership) {
        return value !== null
            ? customerMap[value]?.filter((value) => value !== "Medlemmer") ||
                  null
            : null;
    }

    return value !== null ? customerMap[value] : null;
};

export default Customer;
export {
    type CustomerValues,
    type DbCustomerValues,
    toEnumValue,
    toHumanValue,
    DbtoHumanValue,
};
