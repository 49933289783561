import { FormGroup } from "frno-react";
import classNames from "classnames";

const YesNo = ({
    id,
    value,
    required = false,
    tooltip = "",
    tag = "h2",
    label,
    className = undefined,
    invalid = false,
    onChange,
}: {
    id: string;
    value: boolean | undefined;
    required?: boolean;
    tag?: "h2" | "p";
    tooltip?: string;
    label: string;
    className?: string;
    invalid?: boolean;
    onChange: (val: boolean) => void;
}) => {
    return (
        <>
            {tag === "h2" ? (
                <h2 className={classNames("fs-admin fw-500", className)}>{label}</h2>
            ) : (
                <div className="group-header flex justify-content-space-between">
                    <p className={className}>
                        {label}
                        {required && <span className="required">*</span>}
                    </p>
                    {tooltip && (
                        <div className="tooltip-container">
                            <i className="fa-solid fa-circle-info"></i>
                            <span className="tooltip-text">{tooltip}</span>
                        </div>
                    )}
                </div>
            )}
            <FormGroup
                type="radio"
                mt={2}
                id={id}
                options={[
                    {
                        label: "Ja",
                        value: 1,
                    },
                    {
                        label: "Nei",
                        value: 0,
                    },
                ]}
                className={classNames({
                    "is-invalid": invalid,
                })}
                value={value === undefined ? undefined : value ? 1 : 0}
                onChange={(e) => onChange(e.target.value === "1")}
            />
        </>
    );
};

export default YesNo;
