import { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { LoadingSpinner, Section } from "frno-react";
import classNames from "classnames";
import { useAuth, useStore, useUser } from "./hooks";
import { Header, Footer, Sidebar } from "./components";
import "./App.scss";
import ModalHandler from "./components/Modal/ModalHandler";
import { User } from "./types";
import { isAdmin } from "./util/user";

const Index = lazy(() => import("./pages/Index"));
const Login = lazy(() => import("./pages/login/Index"));
const Products = lazy(() => import("./pages/products/Index"));
const Organizations = lazy(() => import("./pages/organizations/Index"));
const Users = lazy(() => import("./pages/users/Index"));
const Approve = lazy(() => import("./pages/products/Approve/index"));
const Routines = lazy(() => import("./pages/Routines"));
const Associations = lazy(() => import("./pages/associations/Index"));

const AuthenticatedRoute = ({ isAuthenticated = false }) => {
    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

const PublicRoute = ({
    isAuthenticated = false,
    user = undefined,
}: {
    isAuthenticated: boolean;
    user: User | undefined;
}) => {
    return isAuthenticated ? (
        <Navigate
            to={
                user === undefined || isAdmin(user)
                    ? "/"
                    : user?.organizations && user.organizations.length === 1
                    ? `/leverandorer/${user.organizations[0].slug}`
                    : "/leverandorer"
            }
        />
    ) : (
        <Outlet />
    );
};

function App() {
    useAuth();
    const { authToken, menuAnimating, menuExpanded, setMenuAnimating } =
        useStore();
    const [menuExpanding, setMenuExpanding] = useState<boolean>(false);
    const { data: user } = useUser();

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (menuExpanded) {
            setMenuExpanding(true);
            timeout = setTimeout(() => {
                setMenuExpanding(false);
            }, 200);
            document.body.classList.add("no-scroll");
        } else {
            setMenuExpanding(true);
            timeout = setTimeout(() => {
                setMenuExpanding(false);
            }, 200);
            document.body.classList.remove("no-scroll");
        }

        return () => clearTimeout(timeout);
    }, [menuExpanded, menuAnimating]);

    return (
        <>
            <div
                id="overlay"
                className={classNames({
                    block: menuExpanded || menuExpanding,
                    "is-active": menuExpanded && !menuAnimating,
                })}
                onClick={() => setMenuAnimating()}
                onFocus={() => setMenuAnimating()}
            ></div>
            <ModalHandler />
            <Header />
            <main className="flex">
                <Sidebar />
                <section className="admin-container">
                    <Suspense
                        fallback={
                            <Section className="container">
                                <LoadingSpinner />
                            </Section>
                        }
                    >
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <AuthenticatedRoute
                                        isAuthenticated={authToken !== null}
                                    />
                                }
                            >
                                <Route
                                    path="/produkter/*"
                                    element={<Products />}
                                />

                                <Route
                                    path="/leverandorer/*"
                                    element={<Organizations />}
                                />
                                <Route path="/brukere/*" element={<Users />} />
                                <Route
                                    path="/medlemskap/*"
                                    element={<Associations />}
                                />
                                <Route path="/rutiner" element={<Routines />} />
                                <Route path="/" element={<Index />} />
                                <Route
                                    path="/godkjenn/*"
                                    element={<Approve />}
                                />
                            </Route>
                            <Route
                                path="/"
                                element={
                                    <PublicRoute
                                        isAuthenticated={authToken !== null}
                                        user={user}
                                    />
                                }
                            >
                                <Route path="/login/*" element={<Login />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default App;
