import { useMutation, useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { isFuture } from "date-fns";
import type { DbProductTypeValues } from "../enum/productType";
import type { DbPublishingStatusValues } from "../enum/publishingStatus";
import type { Organization, Product } from "../types";
import axios from "../util/axios";
import { DbVersionToEnumValue } from "../util/productVersion";
import { isAdmin } from "../util/user";
import { DbHumanProductVersion } from "../types/productVersion";
import { useStore, useUser } from ".";

interface DbProduct {
    id: number;
    name: string;
    type: DbProductTypeValues;
    status: DbPublishingStatusValues;
    organization: Organization;
    versions: DbHumanProductVersion[];
    createdAt: Date | null;
    updatedAt: Date | null;
    activatedAt: Date | null;
    deletedAt: Date | null;
}

const usePendingProducts = (limit: number | undefined = undefined) => {
    const { authToken } = useStore();
    const { data: user } = useUser();
    const apiReturn = async (): Promise<{
        products: Product[];
        count: number;
    }> => {
        if (!isAdmin(user)) {
            return {
                products: [],
                count: 0,
            };
        }
        const response: AxiosResponse<{
            products: DbProduct[];
            count: number;
        }> = await axios.get(
            `products/pending${limit !== undefined ? `?limit=${limit}` : ""}`,
        );

        const resp = {
            ...response,
            data: {
                ...response.data,
                products: response.data.products.map((p) => ({
                    ...p,
                    versions: p.versions.map((v) => DbVersionToEnumValue(v)),
                })),
            },
        };

        return resp.data;
    };

    // FIXME add types
    return useQuery({
        queryKey: ["products", "pending", limit],
        queryFn: apiReturn,
        refetchInterval: 1000 * 60 * 5,
        enabled:
            authToken !== null &&
            isFuture(new Date(authToken.expiresAt)) &&
            !!user?.id,
    });
};

const usePendingProduct = (id: number) => {
    const { authToken } = useStore();
    const apiReturn = async (): Promise<Product> => {
        const response = await axios.get<DbProduct>(`products/pending/${id}`);

        const resp: AxiosResponse<Product, Product> = {
            ...response,
            data: {
                ...response.data,
                versions: response.data.versions.map((version) =>
                    DbVersionToEnumValue(version),
                ),
            },
        };

        return resp.data;
    };

    // FIXME add types
    return useQuery({
        queryKey: ["product", "pending", id],
        queryFn: apiReturn,

        enabled: authToken !== null && isFuture(new Date(authToken.expiresAt)),
    });
};

const mutatePendingProduct = (versionId: string | number, approve: boolean) => {
    const apiReturn = async () => {
        return (
            await axios.put<unknown>(`products/pending/${versionId}`, {
                approve,
            })
        ).data;
    };

    // FIXME add types
    return useMutation({
        mutationKey: ["product", "pending", versionId],
        mutationFn: apiReturn,
    });
};

export { usePendingProducts, mutatePendingProduct, usePendingProduct };
